import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Cookies from 'universal-cookie'
import smoothscroll from 'smoothscroll-polyfill'
import { abbreviateState, setDefaultLocation } from '@helpers/geo-location'
import { getLineItemQuantity } from '@helpers/cart'
import { getFromBrowserStorage } from '@helpers/storage'
import { setShippingAddress, setLocation, setStateExclusions } from '@redux/modules/location'
import { setCart } from '@redux/modules/cart'
import { setOrder } from '@redux/modules/checkout'
import { getStateExclusions } from '@services/state-exclusions'
import { Buffer } from 'buffer/'
import Layout from './layout'

if (typeof window !== 'undefined') {
  smoothscroll.polyfill()
  window.Buffer = Buffer
}

class LayoutWrapper extends Component {
  state = {
    scrolled: false,
  }

  componentDidMount() {
    const cookies = new Cookies()
    const userId = getFromBrowserStorage('local', 'uuid')
    const {
      rtg_location,
      order,
      shipping_address,
      cart,
      isLandscape,
      onSetCart,
      onSetLocation,
      onSetOrder,
      onSetShippingAddress,
    } = this.props

    this.removeUnusedCookies(cookies)

    if (process.env.NODE_ENV === 'development') {
      if (!cookies.get('__Host-rtgp')) {
        cookies.set('__Host-rtgp', 'e5d49640-5af6-11e9-b882-6197050ecff1', {
          path: '/',
          secure: true,
          sameSite: 'lax',
        })
      }
      if (!cookies.get('__Host-TL_RTG')) {
        cookies.set('__Host-TL_RTG', 'c823fdec-3136-ed38-8477-601659e5cb70', {
          path: '/',
          secure: true,
          sameSite: 'lax',
        })
      }
    }

    if (isLandscape) {
      window.addEventListener('scroll', this.updateScrollPosition)
    }
    const changeLocRefresh = getFromBrowserStorage('local', 'changeLocReturn')
    if (changeLocRefresh) {
      try {
        document.getElementById(changeLocRefresh).focus()
        localStorage.removeItem('changeLocReturn')
      } catch (err) {}
    }

    const rtg_location_cookie = setDefaultLocation(cookies.get('__Host-rtg_location'))
    if (rtg_location_cookie) {
      const shippingAddress = `${rtg_location_cookie.city}, ${abbreviateState(rtg_location_cookie.state) ??
        rtg_location_cookie.region}`
      if (shippingAddress !== shipping_address) {
        onSetShippingAddress(shippingAddress)
      }
    }
    if (rtg_location_cookie && rtg_location && rtg_location.zip !== rtg_location_cookie.zip) {
      onSetLocation(rtg_location_cookie)
    }
    if (process.env.NODE_ENV === 'development' && !rtg_location_cookie) {
      onSetLocation()
    }
    this.setCartFromLocalStorage()
    if (window) {
      window.addEventListener('storage', () => {
        this.setCartFromLocalStorage()
      })
    }
    const orderStorage = getFromBrowserStorage('session', 'order')
    if (orderStorage && orderStorage.orderId && JSON.stringify(order) !== JSON.stringify(orderStorage)) {
      onSetOrder(orderStorage)
    }
  }

  async componentDidUpdate(prevProps) {
    const { isLandscape, shipping_address, onLoadStateExclusions } = this.props
    if (prevProps.isLandscape !== isLandscape && isLandscape) {
      window.addEventListener('scroll', this.updateScrollPosition)
    } else if (prevProps.isLandscape !== isLandscape && !isLandscape) {
      window.removeEventListener('scroll', this.updateScrollPosition)
    }
    const currentShippingStateAbbreviation = shipping_address.slice(-2)
    if (prevProps.shipping_address.slice(-2) !== currentShippingStateAbbreviation) {
      const exclusions = await getStateExclusions(currentShippingStateAbbreviation)
      onLoadStateExclusions(exclusions)
    }
  }

  shouldComponentUpdate(nextProps) {
    const { cart, order } = this.props
    if (nextProps.cart !== cart || nextProps.order !== order) {
      return false
    }
    return true
  }

  removeUnusedCookies(cookies) {
    cookies.remove('rtg_distribution_index')
    cookies.remove('rtg_location')
    // cookies.remove('rtg_region')
    cookies.remove('TL_RTG')
    cookies.remove('rtg_zip')
    cookies.remove('session_id')
  }

  updateScrollPosition = () => {
    const { scrolled } = this.state
    if (window.scrollY > 150 && !scrolled) {
      this.setState({
        scrolled: true,
      })
    } else if (window.scrollY < 150 && scrolled) {
      this.setState({
        scrolled: false,
      })
    }
  }

  setCartFromLocalStorage = () => {
    const { cart, onSetCart } = this.props
    const cartStorage = getFromBrowserStorage('local', 'cart')
    if (
      cartStorage &&
      cartStorage.cartItems &&
      JSON.stringify(cart.cartItems) !== JSON.stringify(cartStorage.cartItems)
    ) {
      onSetCart(cartStorage)
    }
  }

  render() {
    const { order, isProductPage } = this.props
    const cartQuantity = getLineItemQuantity(order).cart
    return <Layout data={this.props} cartQuantity={cartQuantity} isProductPage={isProductPage} />
  }
}

LayoutWrapper.propTypes = {
  cart: PropTypes.shape({
    cartItems: PropTypes.any,
  }),
  isProductPage: PropTypes.bool,
  isLandscape: PropTypes.any,
  onSetCart: PropTypes.func,
  onSetLocation: PropTypes.func,
  onSetOrder: PropTypes.func,
  onSetShippingAddress: PropTypes.func,
  order: PropTypes.any,
  rtg_location: PropTypes.shape({
    zip: PropTypes.any,
  }),
  shipping_address: PropTypes.any,
  onLoadStateExclusions: PropTypes.func,
}

LayoutWrapper.defaultProps = {
  isProductPage: false,
}

LayoutWrapper.defaultProps = {
  isProductPage: false,
}

const mapStateToProps = state => ({
  isLandscape: state.global.isLandscape,
  isMobile: state.global.isMobile,
  showSearchResults: state.global.showSearchResults,
  rtg_location: state.location.rtg_location,
  shipping_address: state.location.shipping_address,
  order: state.checkout.order,
  cart: state.cart.cart,
})

const mapDispatchToProps = dispatch => ({
  onSetShippingAddress: shipping_address => dispatch(setShippingAddress(shipping_address)),
  onSetLocation: location => dispatch(setLocation(location)),
  onSetCart: cart => dispatch(setCart(cart)),
  onSetOrder: order => dispatch(setOrder(order)),
  onLoadStateExclusions: exclusions => dispatch(setStateExclusions(exclusions)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutWrapper)
