import giftCardImage from '@assets/images/gift-cards.jpg'
import FortegraModal from '@shared/modals/fortegra-modal'
import GuardianModal from '@shared/modals/guardian-modal'
import BedsToGoModal from '@shared/modals/bedstogo-modal'
import CasperModal from '@shared/modals/casper-modal'
import { fetchProductWarehouseAvailability, fetchProductBySku } from '@services/product'
import { getLocation } from '@services/location'
import { getSavedCouponType, hasSavedCoupon } from '@services/coupon'
import { isHexColorValid } from '@helpers/hexColor'
import { getRegionZone, getCurrentLocation, getZip } from './geo-location'
import { months } from './string-helper'
import { saveLocalStorage, getFromBrowserStorage } from './storage'
import { getDateFull } from './date'

/**
 * Product delivery types (ENUM)
 * @type Record<string, string>
 */
export const DELIVERY_TYPES = {
  O: 'O',
  U: 'U',
  D: 'D',
}

/**
 * Validates availability of a given product for the current region
 * @param {object} product
 * @returns {boolean}
 */
export const productAvailability = (product, inputRegion) => {
  let { region } = getRegionZone()
  region = inputRegion || region
  const { catalog_availability, delivery_type } = product || {}

  if (catalog_availability && region && delivery_type !== DELIVERY_TYPES.O && delivery_type !== DELIVERY_TYPES.U) {
    return catalog_availability[region]
  }

  if (delivery_type === DELIVERY_TYPES.O || delivery_type === DELIVERY_TYPES.U) {
    return true
  }

  return false
}

export const weeksBetween = (date1, date2) => {
  const ONE_WEEK = 1000 * 60 * 60 * 24 * 7
  const difference_ms = Math.abs(date1.getTime() - date2.getTime())
  return Math.floor(difference_ms / ONE_WEEK)
}

export const daysBetween = (date1, date2) => {
  const ONE_DAY = 24 * 60 * 60 * 1000
  const difference_ms = Math.abs(date1.getTime() - date2.getTime())
  return Math.floor(difference_ms / ONE_DAY)
}

export function productPrice(product, strikethrough = false, regionInput = '', zoneInput = '') {
  if (!product) return -1

  const { pricing, default_price, thank_you_pricing, delivery_type } = product
  let { region, zone } = getRegionZone()
  region = regionInput || region
  zone = zoneInput || zone
  if (delivery_type === 'O') {
    region = 'FL'
    zone = '0'
  }

  // thank you coupon
  if (hasSavedCoupon() && getSavedCouponType() !== 'OFF' && thank_you_pricing) {
    const defaultPrice = pricing?.default_price || product[`zone_${zone}_list_price`]
    return strikethrough ? defaultPrice : thank_you_pricing[`${region}_${zone}`]
  }

  if (pricing) {
    const region_zone = `${region}_${zone}`
    const price =
      pricing[`${region_zone}_sale_price`] && !strikethrough
        ? pricing[`${region_zone}_sale_price`]
        : pricing[`${region_zone}_list_price`]
    return price || pricing.default_price
  }

  const price =
    product[`zone_${zone}_sale_price`] && !strikethrough
      ? product[`zone_${zone}_sale_price`]
      : product[`zone_${zone}_list_price`]
  return price || default_price || product.price || 0
}

export function getWarrantyDataByRegionZone(warrantyOptions, regionZoneData) {
  if (warrantyOptions && regionZoneData) {
    const warrantyData = warrantyOptions[`${regionZoneData.region}_${regionZoneData.zone}`]
    return warrantyData || null
  }
  return null
}

export function getWarrantyModalByProvider(warrantyData) {
  if (warrantyData && warrantyData.provider_name) {
    if (warrantyData.provider_name === 'GUARDIAN') return GuardianModal
    if (warrantyData.provider_name === 'FORTEGRA') return FortegraModal
  }
  return null
}

export function getSleepTrialModalByBrand(product) {
  if (product && product.brand) {
    if (product.brand.toLowerCase() === 'beds to go') return BedsToGoModal
    if (product.brand.toLowerCase() === 'casper') return CasperModal
  }
  return null
}

export function productOnSale(product) {
  if (!product) return false
  let { region, zone } = getRegionZone()
  if (product.delivery_type === 'O') {
    region = 'FL'
    zone = '0'
  }
  return product.on_sale ? product.on_sale[`${region}_${zone}`] : product[`zone_${zone}_on_sale`]
}

/**
 * getTileDataWithBanners
 * factory function to decorate search result products with plpBanners
 * @param {*} hits
 * @param {*} plpBanners
 */
export const getTileDataWithBanners = (hits, plpBanners) => {
  if (!plpBanners || plpBanners?.length === 0) return hits
  const tiles = [...hits]
  plpBanners.forEach(plpBannerData => {
    if (plpBannerData?.Banner?.Banner) {
      const { Banner, TileIndex } = plpBannerData
      const tileIndex = TileIndex
      const plpBannerTileData = {
        tileIndex,
        isPLPBanner: true,
        Banner: Banner.Banner,
      }
      /*
      By setting tileIndex to tileIndex - 1, we are 0-indexing the tileIndex provided from Contentful.
      In Contentful, if tileIndex === 1, we want it to reference the 0th element in the tiles array
    */
      const zeroIndexedTileIndex = tileIndex - 1
      tiles.splice(zeroIndexedTileIndex, 0, plpBannerTileData)
    }
  })
  return tiles
}

/**
 * isProductStrikeThrough
 * Determine if a product is on clearance or not. If yes, display a strikethrough price.
 * @param {*} product
 * @returns {boolean}
 */
export const isProductStrikeThrough = product => {
  const location = getCurrentLocation()
  if (location) {
    const { region, price_zone } = location
    const { thank_you_pricing, strikethrough, pricing, price, list_price } = product

    /* check to see if we need to add a strikethrough for 'thank you' coupons */
    const hasCoupon = hasSavedCoupon()
    if (hasCoupon) {
      const couponType = getSavedCouponType()
      switch (couponType) {
        case 'OFF':
          break
        default:
          if (thank_you_pricing && thank_you_pricing[`${region}_${price_zone}`]) {
            return true
          }
          break
      }
    }
    return (
      strikethrough &&
      (strikethrough[region] || strikethrough === true) &&
      ((pricing &&
        pricing[`${location.region}_${price_zone}_list_price`] !==
          pricing[`${location.region}_${price_zone}_sale_price`]) ||
        product[`zone_${price_zone}_sale_price`] !== product[`zone_${price_zone}_list_price`] ||
        price !== list_price)
    )
  }
  return false
}

// Memoization Example
// eslint-disable-next-line func-names
export const getRegionSkuList = (function() {
  const cache = {}
  return (list, region) => {
    const key = JSON.stringify([list, region])
    if (!cache[key]) {
      if (list) {
        if (region === 'FL' && list.FL) {
          cache[key] = list.FL
        }
        if (region === 'SE' && list.SE) {
          cache[key] = list.SE
        }
        if (region === 'TX' && list.TX) {
          cache[key] = list.TX
        }
        if (region === 'OOM' && list.OOM) {
          cache[key] = list.OOM
        }
        if (region === 'PR' && list.PR) {
          cache[key] = list.PR
        }
      }
    }
    return cache[key]
  }
})()

export const availabilityStockMessage = (
  { availableOn, product, rtg_location, setStockMessage = null },
  setProductAvailability,
) => {
  let availability = false
  let stockMessage
  const today = new Date()
  const availableDate = new Date(availableOn)
  const availableSoonDateThreshold = new Date('2999-12-31T00:00:00.000Z')
  const weeks = weeksBetween(today, availableDate)
  const days = daysBetween(today, availableDate)
  const shipCostCode = product.shipping_cost_code?.[rtg_location.region] || ''
  if (availableOn === 'Unavailable') {
    stockMessage = 'Unavailable '
  } else if (availableOn === '9999-12-31T00:00:00.000Z') {
    stockMessage = 'Discontinued '
  } else if (availableDate.getTime() >= availableSoonDateThreshold.getTime()) {
    stockMessage = 'Available Soon '
    availability = true
  } else if (product.delivery_type === 'O' || product.delivery_type === 'U') {
    availability = true
    if (availableDate.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0)) {
      stockMessage = `Ships direct from vendor. Item available after ${
        months[availableDate.getMonth()]
      } ${availableDate.getDate()}.`
    } else if (shipCostCode === 'LT') {
      stockMessage = `The carrier will contact you directly to schedule your delivery, which includes placing box into the first room of your home.`
    } else if (shipCostCode === 'ITEM' && product.delivery_type === 'O') {
      stockMessage = `Ships direct from vendor. Allow 4-6 weeks for delivery.`
    } else if (shipCostCode === 'UD') {
      stockMessage = `Ships via UPS. Allow 7-10 days for delivery.`
    } else if (shipCostCode === 'UDR') {
      stockMessage = `Ships via UPS, usually within a week.`
    } else if (shipCostCode === 'VD23') {
      stockMessage = `Ships direct from vendor. Allow 2-3 weeks for delivery.`
    } else if (shipCostCode === 'VD35') {
      stockMessage = `Ships direct from vendor. Usually within a week.`
    } else if (shipCostCode === 'VD71') {
      stockMessage = `Ships direct from vendor, usually within 7 to 10 business days.`
    } else if (shipCostCode === 'VDEX') {
      stockMessage = `Ships direct from vendor, usually within 2 to 3 business days.`
    } else if (shipCostCode === 'VDR') {
      stockMessage = `Ships direct from vendor, usually within a week.`
    } else {
      stockMessage = `${product.delivery_type === 'U' ? 'Ships via UPS.' : 'Ships direct from vendor.'}`
    }
  } else if (days > 120) {
    stockMessage = 'Usually available in 4-6 months '
    availability = true
  } else if (weeks >= 2) {
    stockMessage = `Available as soon as ${getDateFull(availableDate)} `
    availability = true
  } else {
    stockMessage = 'Available Now '
    availability = true
  }

  if (setProductAvailability) {
    setProductAvailability(availability)
  }

  if (setStockMessage && stockMessage) {
    setStockMessage(enhanceStockMessage(stockMessage))
  }

  return enhanceStockMessage(stockMessage)
}

/**
 * Set product availablity synchronously if availability info is already available. Good for ssr pdps.
 *
 * @param product the product.json (SKU) you want to search
 * @returns {null|Promise<T>}
 */
export const getStockMessageSync = product => {
  let earliestAvailability
  let isAvailable
  let stockMessage
  /* get the location to check for region (SE, OOM, etc) */
  const rtg_location = getCurrentLocation()
  if (product?.delivery_type !== 'O' && product?.delivery_type !== 'U' && rtg_location?.region === 'OOM') {
    stockMessage = 'Not available in your region '
    isAvailable = false
  } else {
    stockMessage = enhanceStockMessage('Available Now ')
  }
  if (
    product?.availabilityInfo &&
    product?.sku !== '83333333' &&
    (rtg_location?.region !== 'OOM' ||
      ((product?.delivery_type === 'O' || product?.delivery_type === 'U') && rtg_location?.region === 'OOM'))
  ) {
    /* call the warehouse availability */
    try {
      const { data } = product.availabilityInfo
      earliestAvailability = data?.earliestAvailability
      isAvailable = productIsAvailable(data)
      /* if the product type is a room and the items are eligible for split delivery, show a unique message */
      if (Object.is(product?.type, 'room') && data?.availabilities[0]?.splitDeliveryMessage && isAvailable) {
        stockMessage = enhanceStockMessage(data.availabilities[0].splitDeliveryMessage)
      } else {
        /* otherwise, show the standard availability message */
        const isAvailableInRegion = data?.availabilities?.[0]?.catalog_availability?.[rtg_location.region] || false

        const availableOn = isAvailableInRegion ? data?.earliestAvailability || 'Unavailable' : 'Unavailable'

        stockMessage = availabilityStockMessage({ availableOn, product, rtg_location }, availability => {
          isAvailable = availability
        })
      }
    } catch (error) {
      stockMessage = 'Out of Stock '
      isAvailable = false
    }
  }
  return { earliestAvailability, isAvailable, stockMessage }
}

// TODO: All occurrences of setProductAvailability should be pull out from getStockMessage
/**
 * this function calls the warehouse availability to determine if a product is in stock
 *
 * @param product the product.json (SKU) you want to search
 * @param setStockMessage the setState object you want the result to be posted to
 * @returns {null|Promise<T>}
 */
export const getStockMessage = (product, setStockMessage, setProductAvailability) => {
  /* get the location to check for region (SE, OOM, etc) */
  const rtg_location = getCurrentLocation()
  if (
    product?.sku !== '83333333' &&
    (rtg_location?.region !== 'OOM' ||
      ((product?.delivery_type === 'O' || product?.delivery_type === 'U') && rtg_location?.region === 'OOM'))
  ) {
    /* call the warehouse availability */
    return fetchProductWarehouseAvailability(product.sku, rtg_location.distribution_index, rtg_location.state)
      .then(({ data }) => {
        /* if the product type is a room and the items are eligible for split delivery, show a unique message */
        if (
          Object.is(product?.type, 'room') &&
          data?.availabilities[0]?.splitDeliveryMessage &&
          data?.availabilities[0].catalog_availability[rtg_location.region]
        ) {
          if (setProductAvailability) {
            setProductAvailability(true)
          }

          setStockMessage(enhanceStockMessage(data.availabilities[0].splitDeliveryMessage))
        } else {
          /* otherwise, show the standard availability message */
          const isAvailableInRegion = data?.availabilities?.[0]?.catalog_availability?.[rtg_location.region] || false

          const availableOn = isAvailableInRegion ? data?.earliestAvailability || 'Unavailable' : 'Unavailable'

          availabilityStockMessage({ availableOn, product, rtg_location, setStockMessage }, setProductAvailability)
        }
      })
      .catch(err => {
        setStockMessage('Out of Stock ')
        if (setProductAvailability) {
          setProductAvailability(false)
        }
      })
  }
  if (product?.delivery_type !== 'O' && product?.delivery_type !== 'U' && rtg_location?.region === 'OOM') {
    setStockMessage('Not available in your region ')
    if (setProductAvailability) {
      setProductAvailability(false)
    }
  } else {
    setStockMessage(enhanceStockMessage('Available Now '))
    if (setProductAvailability) {
      setProductAvailability(true)
    }
  }
  return null
}

export const productIsAvailable = availability => {
  const rtg_location = getCurrentLocation()
  return availability?.availabilities[0].catalog_availability[rtg_location.region]
}

export const getProductAvailableIn = (date, days = 60) => {
  const today = new Date()
  const earliestAvailabilityDate = new Date(date)
  const differenceInDays = (earliestAvailabilityDate.getTime() - today.getTime()) / (1000 * 3600 * 24)

  return differenceInDays < days
}

export const getRequiredAddon = requiredAddons => {
  let requiredAddon
  if (requiredAddons) {
    let title
    let decline
    let price = 0

    const skus = []

    requiredAddons.forEach((addon, i) => {
      if (addon.quantity === 1 && addon.title?.includes('Bunkie Board')) {
        // make bunkie board(s) singular or plural
        addon.title = addon.title.replace(/Boards/g, 'Board')
      }

      if (i > 0) {
        title = `${title} and ${addon.title}${addon.quantity > 1 ? ` (${addon.quantity})` : ''}`
      } else {
        title = `${addon.title}${addon.quantity > 1 ? ` (${addon.quantity})` : ''}`
      }

      decline = i > 0 ? `${decline} and ${addon.title}` : `${addon.title}`
      price += productPrice(addon) * addon.quantity
      skus.push(addon.sku)
    })

    requiredAddon = {
      title,
      price,
      skus,
      decline,
    }
  }
  return requiredAddon
}

export const isActiveAddon = (activeAddons, addonSku) =>
  activeAddons ? activeAddons.some(activeAddon => activeAddon.sku === addonSku) : false

export const savePageScroll = () => {
  if (
    window.location.pathname.includes('/furniture') ||
    window.location.pathname.includes('/mattress') ||
    window.location.pathname.includes('/search')
  ) {
    saveLocalStorage('rtg_scroll', {
      scrollY: window.scrollY,
      pathForScroll: window.location.pathname,
    })
  }
}

export const scrollToPLP = () => {
  const rtg_scroll = getFromBrowserStorage('local', 'rtg_scroll')
  if (rtg_scroll && rtg_scroll.scrollY && rtg_scroll.pathForScroll) {
    if (window.location.pathname === rtg_scroll.pathForScroll) {
      window.scrollTo(0, rtg_scroll.scrollY)
    } else {
      saveLocalStorage('rtg_scroll', {})
    }
  }
}

export const getGiftCardProductData = () => ({
  sku: '83333333',
  delivery_type: 'T',
  price: 100,
  unitPrice: 100,
  title: 'Gift Card',
  primary_image: giftCardImage,
  grid_image: giftCardImage,
  category: 'gift-card',
  catalog: 'gift-card',
  pricing: { default_price: 100 },
})

export const fetchProductOrGC = sku => {
  if (sku === '83333333') {
    return new Promise(resolve => resolve(getGiftCardProductData()))
  }
  return fetchProductBySku(sku)
}

export const getProductImages = product => {
  const { single_item_room, primary_image_item, primary_image_room, primary_image, alternate_images } = product
  let images = []
  if (single_item_room) {
    if (primary_image_item) {
      images.push(primary_image_item)
    } else {
      images.push(primary_image)
    }
    if (primary_image_room) {
      images.push(primary_image_room)
    }
  } else {
    if (primary_image_room) {
      images.push(primary_image_room)
    } else {
      images.push(primary_image)
    }
    if (primary_image_item) {
      images.push(primary_image_item)
    }
  }
  if (alternate_images) {
    images = images.concat(alternate_images)
  }

  return Array.from(new Set(images))
}

/* unsure if this function is used anywhere */
export const sortByPriceAscending = products => {
  if (products != null) {
    return products.slice().sort((a, b) => productPrice(a) - productPrice(b))
  }
  return products
}

export const sortByPriceDescending = products => {
  if (products != null) {
    return products.slice().sort((a, b) => productPrice(b) - productPrice(a))
  }
  return products
}

export const earliestStockDate = product => {
  if (product?.warehouseAvailability?.data?.earliestAvailability) {
    return product.warehouseAvailability.data.earliestAvailability
  }
  return ''
}

/* this is what will be shown to the user if not overridden */
export const SaleFlagType = {
  BonusBuy: 'BONUS BUY',
  Closeout: 'CLOSEOUT',
  Sale: 'SALE',
  InStock: 'IN STOCK',
  InStockAndOnSale: 'IN STOCK & ON SALE',
  None: '',
}

/**
 * what should we display in the sale flag
 *
 * @param {{product, availabilityDate:Date, sale:boolean }} args
 * @returns {typeof SaleFlagType[keyof SaleFlagType]}
 */
export function getProductSaleFlagType({ product, availabilityDate, sale }) {
  const { region, zone } = getRegionZone()
  const price = productPrice(product)
  const checkBonusBuy = () =>
    /* only show flag if bonus buy offer meets criteria */
    product?.promotions?.[`${region}_${zone}`]?.qualifier_value < price ||
    product?.promotions?.[`${region}_0`]?.qualifier_value < price

  const checkCloseout = () =>
    /* only show flag if bonus buy offer meets criteria */
    (product?.closeout?.[`${region}`] && Object.is(typeof product?.closeout, 'object')) ||
    (product?.closeout && !Object.is(typeof product?.closeout, 'object'))

  /*  if PDP we'll use the prop, if PLP we'll grab date from algolia product */
  const checkInStock = date => weeksBetween(new Date(date), new Date()) < 2 || date?.includes('Available Now')

  /* this is the order of priority, bonus buy displays before closeout, etc... */
  if (checkBonusBuy()) return SaleFlagType.BonusBuy
  if (checkCloseout()) return SaleFlagType.Closeout
  if ((productOnSale(product) || sale) && checkInStock(availabilityDate || product?.availabilityDate))
    return SaleFlagType.InStockAndOnSale
  if (productOnSale(product) || sale) return SaleFlagType.Sale
  if (checkInStock(availabilityDate || product?.availabilityDate)) return SaleFlagType.InStock
  return SaleFlagType.None
}

/* Custom Sale Flag */
export const CustomSaleFlagData = {
  text: '',
  bgColor: '',
  textColor: '',
}

/**
 *
 * @param {product: object} args
 * @returns {typeof CustomSaleFlagData || null}
 */
export const getCustomSaleFlag = product => {
  const { region } = getRegionZone()
  const customSaleFlag = product?.saleFlag?.[region]?.text ? product.saleFlag[region] : null
  if (!customSaleFlag) return null
  return {
    text: customSaleFlag.text,
    bgColor: isHexColorValid(customSaleFlag['background-color']) ? customSaleFlag['background-color'] : null,
    textColor: isHexColorValid(customSaleFlag['text-color']) ? customSaleFlag['text-color'] : null,
  }
}

/**
 *
 * @param {{delivery_sub_type_code, vendorId }} args
 * @returns {typeof boolean}
 */
export const renderLTLmessage = async ({ delivery_sub_type_code, vendorId }) => {
  if (delivery_sub_type_code !== 'LT') return false
  const location = await getLocation(getZip())
  return Object.is(location?.response?.ltlVendors, vendorId)
}

export const enhanceStockMessage = message => {
  // todo maybe it's good time to refactor the way stockMessages are passed
  // since we're using this function elsewhere, its easiest to keep the styles inline
  const availableAsSoonAs = 'Available as soon as'
  const availableNow = 'Available Now'
  const availableAfter = 'Item available after'
  const vendorDirect = 'Ships direct from vendor.'
  const splitDelivery = 'Some items in this room may be'

  if (message?.includes(splitDelivery)) {
    /* sometimes the split delivery can include the phrase 'Available Now', so we just return the full formatted message */
    return message
  }

  if (message?.includes(availableAsSoonAs)) {
    const split = message.split(availableAsSoonAs)
    return `${availableAsSoonAs}<span style="font-weight: bold">${split[1]}</span>`
  }

  if (message?.includes(availableNow)) {
    return `<span style="font-weight: bold">${message}</span>`
  }

  if (message?.includes(availableAfter) && !message?.includes(vendorDirect)) {
    const split = message.split(availableAfter)
    return `<span style="color: #333;">Available after</span><span style="font-weight: bold">${split[1]}</span>`
  }

  // this just pulls off the vendor direct message. After, we call this function recursively to enhance the stock message.
  if (message?.includes(vendorDirect)) {
    const split = message.split(vendorDirect)
    return `<p style="font-style: normal; line-height: 20px; font-size: 15px;" >${vendorDirect}</p><span>${enhanceStockMessage(
      split[1],
    )}</span>`
  }

  return message
}

/**
 * Determines whether Add to Cart button should render on PLP Grid items, inspired by vendor products available to OOM customers
 * @param {string} region
 * @param {string} delivery_type
 * @returns {boolean}
 */
export const displayAddToCartButton = ({ region, delivery_type }) => {
  if (delivery_type === 'O' || delivery_type === 'U') return true
  if (region === 'OOM') return false
  return true
}

export const renderSeeInStore = ({ delivery_type, region }) =>
  delivery_type !== 'O' && delivery_type !== 'U' && !Object.is(region, 'OOM') && !Object.is(region, 'PR')
