import { useState, useEffect, useCallback, useMemo } from 'react'
import { useAuthState, useSendPasswordResetEmail } from 'react-firebase-hooks/auth'
import {
  signOut,
  confirmPasswordReset,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from 'firebase/auth'
import * as AccountsService from '@services/accounts'
import * as FavoritesService from '@services/favorites'
import { withoutFlickering } from '@helpers/strapi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useModalsContext } from '@context/modalContext'
import { useFirebaseContext } from '@context/firebaseContext'
import useStore from '@store'
import { useNotifications } from '@context/notifications'
import { analyticsProduct, addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { initialize, AuthErrorMessages } from '../auth/firebase'

const auth = initialize()

export const useLogOut = () => {
  const queryClient = useQueryClient()

  const logout = useCallback(() => {
    queryClient.removeQueries('favorites-user')
    queryClient.removeQueries('account-user')
    signOut(auth)
  }, [queryClient])

  return logout
}

export const useAuth = () => useAuthState(auth)

/**
 * SignIn With Email And Password
 */
export const useLoginMutation = (options = {}) => {
  const logIn = useMemo(() => AccountsService.logIn(auth), [])
  return useMutation(logIn, options)
}
/**
 * Create User With Email And Password
 */
export const useCreateUserMutation = (options = {}) => {
  const createAccount = useMemo(() => AccountsService.createAccount(auth), [])
  return useMutation(createAccount, options)
}

/**
 * Updates User Profile data
 */
export const useUpdateProfileMutation = (options = {}) => {
  const queryClient = useQueryClient()

  const updateAccountInfo = useMemo(() => AccountsService.updateAccountInfo(auth), [])

  const onMutate = useCallback(
    async profileInfo => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries('account-user')
      // Snapshot the previous Profile Data
      const previousProfileData = queryClient.getQueryData('account-user')
      // Optimistically update to the new value
      queryClient.setQueryData('account-user', () => AccountsService.getBody(profileInfo))
      return { previousProfileData }
    },
    [queryClient],
  )
  // If the mutation fails, use the context returned from onMutate to roll back
  const onError = useCallback(
    (err, newTodo, context) => {
      queryClient.setQueryData('account-user', context.previousProfileData)
      if (options.onError) {
        options.onError(err)
      }
    },
    [queryClient, options],
  )
  // Always refetch after error or success:
  const onSettled = useCallback(() => queryClient.invalidateQueries('account-user'), [queryClient])

  return useMutation(withoutFlickering(updateAccountInfo), {
    ...options,
    onMutate,
    onError,
    onSettled,
  })
}

export const useUserQuery = (antiFlickering = true) => {
  const { token } = useFirebaseContext()

  return useQuery({
    queryKey: ['account-user', token],
    enabled: Boolean(token),
    queryFn: withoutFlickering(AccountsService.userQuery, 1800, antiFlickering),
    staleTime: Infinity,
  })
}

export const useForgotPassword = () => useSendPasswordResetEmail(auth)

export const resetPasswordByToken = (oobCode, newPassword) => confirmPasswordReset(auth, oobCode, newPassword)

export const setNewPassword = newPassword => updatePassword(auth?.currentUser, newPassword)

export const reauthenticateByCredential = password => {
  const credential = EmailAuthProvider.credential(auth?.currentUser?.email, password)
  return reauthenticateWithCredential(auth?.currentUser, credential)
}

/**
 * Update User's Favorites
 * @param {string} [sku]
 * @returns {func: Promise<void>}
 */
export const useUpdateFavorites = product => {
  const isFavorited = useIsFavorited(product?.sku)
  const { user } = useFirebaseContext()
  const addFavorite = useStore(store => store.favorites.add)
  const removeFavorite = useStore(store => store.favorites.remove)
  const { enqueue, removeById } = useNotifications()

  const handleFavoriteClick = useCallback(() => {
    if (isFavorited) {
      addToDataLayer_nextgen('ee_remove_from_wishlist', {
        ecommerce: {
          remove: {
            products: analyticsProduct(product),
          },
        },
      })
      removeFavorite(user)(product?.sku)
      const id = enqueue({
        variant: 'filled',
        body: 'Removed from Favorites',
        actions: [
          {
            text: 'undo',
            onClick: () => {
              addFavorite(user)(product?.sku)
              removeById(id)
            },
          },
        ],
      })
      return
    }
    addToDataLayer_nextgen('ee_add_to_wishlist', {
      ecommerce: {
        add: {
          products: analyticsProduct(product),
        },
      },
    })
    addFavorite(user)(product?.sku)
    enqueue({ variant: 'filled', body: 'Added to Favorites' })
  }, [isFavorited, addFavorite, user, enqueue, product, removeFavorite, removeById])
  return handleFavoriteClick
}

/**
 *
 * @param {string} sku
 * @returns {boolean}
 */
export const useIsFavorited = sku => {
  const favorites = useStore(store => store.favorites.list)
  const isFavorited = useMemo(() => {
    if (favorites.isEmpty()) return false
    return favorites.has(sku)
  }, [favorites, sku])
  return isFavorited
}

/**
 * Messages for Auth error codes
 * @param {object} error
 * @returns {{ errorMessage: string | null, clearMessage: () => void }}
 */
export const useAuthErrorMessage = error => {
  const [errorMessage, setErrorMessage] = useState(null)
  const clearMessage = useCallback(() => setErrorMessage(null), [])

  useEffect(() => {
    if (!error) return
    console.warn(error.toString())
    setErrorMessage(AuthErrorMessages[error.code] || 'Username/Password combination is incorrect.  Please re-enter.')
  }, [error])

  return { errorMessage, clearMessage, setErrorMessage }
}
