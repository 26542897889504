import React, { useLayoutEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { CircularProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { updatePayment } from '@services/checkout'
import { setCheckoutStep, setOrder } from '@redux/modules/checkout'
import { getSessionItem, setSessionItem } from '@helpers/checkout/checkout'
import { SubmitRtgButton } from './styles'

//  React-queries
function useKlarnaPayment({ orderId, isDirty }) {
  return useQuery(
    ['klarna_token', orderId, isDirty],
    () =>
      axios.get(`${process.env.GATSBY_KLARNA_URL}/token/order/${orderId}`).then(el => {
        const { data } = el
        const { client_token, session_id, klarnaOrder } = data
        setSessionItem('klarna_auth_total', klarnaOrder.order_amount)
        setSessionItem('klarna_session_order', klarnaOrder)
        setSessionItem('klarna_session_id', session_id)
        setSessionItem('klarna_session_token', client_token)
        return {
          session_id,
          klarnaOrder,
        }
      }),
    {
      enabled: isDirty,
      refetchOnWindowFocus: false,
    },
  )
}

export default function KlarnaPayment({ orderId, total }) {
  const [klarnaReady, setKlarnaReady] = useState(false)
  const sessionToken = getSessionItem('klarna_session_token')
  const sessionAuth_Total = getSessionItem('klarna_auth_total')
  const sessionKlarnaOrder = getSessionItem('klarna_session_order')
  const isDirty = sessionAuth_Total !== total.toString().replace('.', '')
  const dispatch = useDispatch()
  const existingPaymentInfo = useSelector(state => state.checkout.order.paymentInfo)

  //  React-Query
  const { data, isLoading, error, isIdle } = useKlarnaPayment({
    orderId,
    isDirty,
  })

  const klarnaOrder = useMemo(() => data?.klarnaOrder || sessionKlarnaOrder, [data, sessionKlarnaOrder])
  useLayoutEffect(() => {
    if (sessionToken) {
      try {
        window.Klarna.Payments.init({
          client_token: sessionToken,
        })
        window.Klarna.Payments.load(
          {
            container: `#klarna-payments-container`,
            payment_method_category: 'pay_later',
            show_form: true,
          },
          res => {
            setKlarnaReady(true)
          },
        )
      } catch (e) {
        // Handle error.
        console.error('error initiating klarna', e)
      }
    }
  }, [sessionToken])

  const authorizeKlarna = authToken => {
    updatePayment({
      orderId,
      paymentInfo: [
        {
          paymentType: 'KLN',
          authorizedAmount: total,
          paymentProperties: {
            authToken,
            orderId,
          },
        },
        ...existingPaymentInfo,
        // {
        //   ****  GIFT CARD EXAMPLE ****
        //   paymentType: 'GIFT',
        //   authorizedAmount: 1000.0,
        //   paymentProperties: {
        //     cardNumber: '7777229957651062',
        //     pin: '81883003',
        //   },
        // },
      ],
    })
      .then(updatedOrder => {
        dispatch(setCheckoutStep('review'))
        dispatch(setOrder(updatedOrder))
      })
      .catch(() => {})
  }

  if (error) {
    return (
      <p style={{ color: '#eb141f' }}>Something went wrong initializing Klarna Payments, please try again later...</p>
    )
  }

  if (isLoading) {
    return <CircularProgress />
  }

  if (sessionToken) {
    return (
      <>
        <div id="klarna-payments-container" />
        <SubmitRtgButton
          variant="contained"
          disabled={!klarnaReady}
          color="primary"
          onClick={() => {
            window.Klarna.Payments.authorize(
              {
                payment_method_category: 'pay_later',
              },
              {
                ...klarnaOrder,
              },
              res => {
                if (res.approved) {
                  authorizeKlarna(res.authorization_token)
                }
                //  Returned error or user cancelled
              },
            )
          }}
        >
          Submit
        </SubmitRtgButton>
      </>
    )
  }
  return null
}

KlarnaPayment.propTypes = {
  orderId: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
}
