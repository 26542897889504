import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { orderBy, isEmpty, isEqual, cloneDeep } from 'lodash'
import { getTileDataWithBanners } from '@helpers/product'
import { useLocation } from '@gatsbyjs/reach-router'
import { breakPoints, colors } from '@constants/styles'
import CircleCounter from '@components/shared/circle-counter'
import Hits from './plp-parts/Hits'
import Stats from './plp-parts/Stats'
import LoadMorePagination from './plp-parts/LoadMorePagination'
import { refine } from './helpers/refine'
import { stateToUrl, initSearchState, windowPush } from './helpers/state'
import SearchResultsContext from './contexts/search-results-context'
import Drawer from './plp-parts/Drawer'
import Sidebar from './search-sidebar'

const LeftBarWrapper = styled.div`
  display: none;
  background-color: ${colors.white};
  height: fit-content;
  min-width: 333px;
  padding: 20px;
  margin-right: 1rem;
  margin-top: 1rem;

  @media only screen and (min-width: ${breakPoints['large-max']}) {
    display: block;
  }
`

const Wrapper = styled.div`
  @media only screen and (min-width: ${breakPoints['large-min']}) {
    display: flex;
  }
`

const SidebarMobileWrapper = styled.div`
  background-color: #f6f6f6;
  min-width: 333px;
  padding: 20px;
`

export const SortFilter = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #0053a0;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;

  > span:first-child {
    margin-right: 5px;
  }
`
const Label = styled(Grid)`
  padding: 20px 18px;
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
`

const applyFilterChange = (newState, key, value) => {
  newState.filters = newState.filters ?? {}
  if (newState.filters?.[key]?.includes(value)) {
    newState.filters[key] = newState.filters[key].filter(item => item !== value)
    if (newState.filters[key].length === 0) delete newState.filters[key]
  } else {
    newState.filters[key] = newState.filters[key] ?? []
    newState.filters[key].push(value)
  }
}

export const getQueryKey = (searchState, oldState) => [
  'plp-results',
  searchState.filters,
  searchState.sortBy,
  oldState?.filters,
]

const SearchResults = props => {
  const {
    displayFilters,
    source,
    matchPath,
    productType,
    queryData,
    plpBanners,
    searchResults,
    onFiltersChange,
  } = props

  const location = useLocation()
  const queryClient = useQueryClient()
  const [filtersChanged, setFiltersChanged] = useState(false)
  const [openModalSidebar, setOpenModalSidebar] = useState(false)
  const [searchState, setSearchState] = useState(initSearchState(searchResults, location, displayFilters))
  const restoredState = queryClient.getQueryData(getQueryKey(searchState, searchResults.content._state))
  const [refinedSearchResults, setRefinedSearchResults] = useState(restoredState || searchResults)
  const [refinementList, setRefinementList] = useState([])
  const [nextRefinmentList, setNextRefinmentList] = useState([])
  const [searching, setSearching] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const isMobile = useSelector(state => state.global.isMobile)
  const [showLabel, setShowLabel] = useState(false)
  const { hits } = refinedSearchResults?.content

  const isBaseFilters = useCallback(state => isEmpty(state.filters), [])

  const isBasePage = useCallback(state => isEqual(state.page, 1), [])

  const isBaseSortBy = useCallback(state => isEqual(state.sortBy, searchResults.state.index), [
    searchResults.state.index,
  ])

  const isBaseState = useCallback(
    state => isBaseFilters(state) && isBasePage(state) && isBaseSortBy(state),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const filterBaseProperties = useCallback(
    state => ({
      ...(!isBaseFilters(state) && state.filters),
      ...(!isBasePage(state) && { page: state.page }),
      ...(!isBaseSortBy(state) && { sortBy: state.sortBy }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const onSearchStateChange = useCallback(() => {
    // IMPORTANT!!! initialLoad of the page can consists of analytics params in the url, be careful while editing this function
    const url = isInitialLoad ? matchPath : stateToUrl(filterBaseProperties(searchState), location)
    setIsInitialLoad(false)
    windowPush(url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchPath, location, searchState])

  const getNextState = useCallback(
    (key, value) => {
      const newState = cloneDeep(searchState)
      const isFilter = displayFilters.includes(key)
      if (isFilter) {
        applyFilterChange(newState, key, value)
      } else {
        newState[key] = value
      }
      if (key !== 'page') {
        newState.page = 1
      }
      return newState
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchState, displayFilters],
  )

  const applyStateChange = useCallback(
    (key, value) => {
      const state = getNextState(key, value)
      setSearchState(state)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getNextState],
  )

  const getUrl = useCallback(
    (key, value) => {
      const state = getNextState(key, value)
      const url = stateToUrl(filterBaseProperties(state), location)
      return url
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
  )

  const clearState = useCallback(() => {
    setSearchState({
      sortBy: searchState.sortBy,
      page: 1,
      filters: {},
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.sortBy])

  const setFacetFilters = useCallback(() => {
    const refinedFacets = refinedSearchResults?.content?.facets || []
    const baseFacets = searchResults?.content?.facets || []
    const newFacetFilters = []
    const refinementFilters = searchState.filters
    displayFilters.forEach(attr => {
      const baseFacet = baseFacets.find(f => attr === f.name) ?? { data: {} }
      const refinedFacet = refinedFacets.find(f => attr === f.name) ?? {
        data: {},
      }
      const items = Object.keys(baseFacet.data).map(filter => ({
        label: filter,
        count: refinedFacet?.data?.[filter] ?? 0,
        isRefined: !!refinementFilters?.[baseFacet.name]?.includes(filter),
      }))
      if (items.length > 1) {
        newFacetFilters.push({
          facetName: attr,
          items: orderBy(items, ['label', 'count'], ['asc', 'desc']),
        })
      }
    })
    if (!isEqual(newFacetFilters, refinementList)) setRefinementList(newFacetFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refinedSearchResults?.content?.facets, searchResults?.content?.facets, searchState.filters])

  const countFilters = () => Object.values(searchState.filters).reduce((acc, next) => acc + next.length, 0)

  const preparedHits = getTileDataWithBanners(hits, plpBanners)

  const statsNode = <Stats nbHits={refinedSearchResults?.content?.nbHits} searching={searching} />
  const filtersQuantity = countFilters()

  const applyFilters = async () => {
    setSearching(true)
    const results = await refine({
      oldState: searchResults.content._state,
      filters: searchState.filters,
      page: searchState.page,
      index: searchState.sortBy,
    })
    setRefinedSearchResults(results)
    setFiltersChanged(true)

    queryClient.prefetchQuery({
      queryKey: getQueryKey(searchState),
      queryFn: () => results,
    })

    setSearching(false)

    return results
  }

  const getNextRefinementState = async page => {
    const currentPage = page ? page + 2 : searchState.page + 1

    const results = await refine({
      oldState: searchResults.content._state,
      filters: searchState.filters,
      page: currentPage,
      index: searchState.sortBy,
    })
    setNextRefinmentList(results)
  }

  async function onLoadMore() {
    const nextRefinment = nextRefinmentList?.content?.hits ?? []
    const newHits = refinedSearchResults ? refinedSearchResults?.content?.hits?.concat(nextRefinment) : nextRefinment

    const result = {
      ...nextRefinmentList,
      content: {
        ...nextRefinmentList.content,
        hits: newHits,
      },
    }
    setRefinedSearchResults(result)

    queryClient.prefetchQuery({
      queryKey: getQueryKey(searchState, searchResults.content._state),
      queryFn: () => result,
    })

    if (newHits.length < refinedSearchResults?.content?.nbHits) {
      const results = await refine({
        oldState: searchResults.content._state,
        filters: searchState.filters,
        page: nextRefinmentList.content._state.page + 2,
        index: searchState.sortBy,
      })
      setNextRefinmentList(results)
    }
  }

  const onLoadMoreWrapper = () => {
    onLoadMore()
  }

  useEffect(() => {
    setFacetFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refinedSearchResults?.content?.facets, searchResults?.content?.facets, searchState.filters])

  useEffect(() => {
    setFiltersChanged(false)
    setOpenModalSidebar(false)
    if (isEqual(refinedSearchResults, searchResults) && isBaseState(searchState)) {
      getNextRefinementState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setShowLabel(isMobile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  useEffect(() => {
    const shouldSearch = () =>
      !isEqual(refinedSearchResults, searchResults) || !isBaseState(searchState, searchResults?.state?.index)

    const restoredStateForFilters = queryClient.getQueryData(getQueryKey(searchState, searchResults.content._state))
    ;(async function getRefinementState() {
      if (shouldSearch()) {
        let res = restoredStateForFilters
        if (!restoredStateForFilters) {
          res = await applyFilters()
        }
        if (res?.content?.hits?.length < res?.content?.nbHits) {
          getNextRefinementState(res?.content?.page)
        }
      }
    })()

    onFiltersChange(searchState.filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => onSearchStateChange(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refinedSearchResults],
  )

  return (
    <SearchResultsContext.Provider
      value={{
        currentRefinements: searchState.filters,
        applyStateChange,
        getUrl,
        clearState,
      }}
    >
      <Wrapper>
        {showLabel && (
          <Label container justify="space-between" alignItems="center">
            <Stats nbHits={refinedSearchResults?.content?.nbHits} searching={searching} />
            <SortFilter onClick={() => setOpenModalSidebar(true)}>
              <span>Sort &amp; Filter</span>
              {filtersQuantity > 0 && <CircleCounter quantity={filtersQuantity} />}
            </SortFilter>
          </Label>
        )}
        <Drawer open={openModalSidebar} onClose={() => setOpenModalSidebar(false)}>
          <SidebarMobileWrapper>
            <Sidebar
              searching={searching}
              searchState={searchState}
              applyStateChange={applyStateChange}
              refinementList={refinementList}
              matchPath={matchPath}
              refinedSearchResults={refinedSearchResults}
              getUrl={getUrl}
              stats={statsNode}
              clearState={clearState}
              setOpenModalSidebar={setOpenModalSidebar}
              openModalSidebar={openModalSidebar}
            />
          </SidebarMobileWrapper>
        </Drawer>

        <LeftBarWrapper>
          <Sidebar
            searching={searching}
            searchState={searchState}
            applyStateChange={applyStateChange}
            refinementList={refinementList}
            matchPath={matchPath}
            refinedSearchResults={refinedSearchResults}
            getUrl={getUrl}
            stats={statsNode}
            clearState={clearState}
          />
        </LeftBarWrapper>
        <Grid container>
          <Hits source={source} productType={productType} hits={preparedHits} filtersChanged={filtersChanged} />

          {!!hits?.length && hits?.length < refinedSearchResults?.content?.nbHits && (
            <div className="pagination">
              <LoadMorePagination
                onLoadMore={onLoadMoreWrapper}
                currentItems={hits?.length}
                totalItems={refinedSearchResults?.content?.nbHits}
              />
            </div>
          )}
        </Grid>
      </Wrapper>
    </SearchResultsContext.Provider>
  )
}

SearchResults.propTypes = {
  displayFilters: PropTypes.array,
  matchPath: PropTypes.any,
  onFiltersChange: PropTypes.func,
  plpBanners: PropTypes.any,
  productType: PropTypes.any,
  queryData: PropTypes.any,
  searchResults: PropTypes.shape({
    content: PropTypes.any,
    state: PropTypes.shape({
      index: PropTypes.any,
    }),
  }),
  source: PropTypes.string,
}

export default SearchResults
