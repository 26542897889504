import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { func, string, objectOf, any, bool } from 'prop-types'
import { getFromBrowserStorage } from '@helpers/storage'
import { setupCheckoutAnalytics } from '@helpers/google-tag-manager'
import { getLineItems } from '@helpers/checkout/global'
import { getCurrentLocation } from '@helpers/geo-location'
import { setCart } from '@redux/modules/cart'
import { setOrder, setCheckoutStep, setCheckoutStepsCompleted } from '@redux/modules/checkout'
import { hasSavedCoupon, getSavedCouponObj, getSavedCouponType } from '@services/coupon'
import { setCheckoutReviewStep } from '@helpers/checkout/local-storage'

import loaderDark from '../../assets/images/loader-dark.svg'
import CondensedContact from '../shared/condensed-contact-links'
import CheckoutSticky from './checkout-sticky/checkout-sticky'
import CheckoutStep from './checkout-parts/checkout-steps'
import CheckoutEmpty from './checkout-parts/empty-checkout'
import ShippingSection from './checkout-parts/shipping-section'
import DeliverySection from './checkout-parts/delivery-section'
import PaymentSection from './checkout-parts/payment-section'
import ReviewSection from './checkout-parts/review-section'
import DeclineModal from './checkout-parts/decline-modal'
import '../../assets/css/components/checkout/checkout.sass'
import { createOrder, updateLineItems } from '../../lib/services/checkout'

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')
class Checkout extends React.Component {
  constructor(props) {
    super(props)
    if (typeof window !== 'undefined' && window.location) {
      if (window.location.href.indexOf('?checkout_token') > 0) {
        setCheckoutReviewStep()
      }
    }
  }

  async componentDidMount() {
    const {
      order,
      order: { shippingAddress, contact },
      onSetOrder,
      checkoutStep,
      onSetCheckoutStep,
      checkoutStepsCompleted,
      onSetCheckoutStepsCompleted,
      onSetCart,
    } = this.props
    const cartStorage = getFromBrowserStorage('local', 'cart')
    const tempOrder = getFromBrowserStorage('session', 'order')
    const storeCartLineItems = order && order.lineItems ? order.lineItems.filter(lineItem => lineItem.isStoreSku) : []
    const lineItems = storeCartLineItems.length > 0 ? [...storeCartLineItems, ...getLineItems()] : getLineItems()

    if (cartStorage) {
      onSetCart(cartStorage)
      setupCheckoutAnalytics(cartStorage, tempOrder)
    }

    const orderStorage = getFromBrowserStorage('session', 'order')
    const orderFromStorageHasItems = orderStorage && orderStorage.lineItems && orderStorage.lineItems.length > 0
    const orderHasBeenUpdated = JSON.stringify(order) !== JSON.stringify(orderStorage)
    const hasDeprecatedPaymentType = this.checkPaymentType(orderStorage)
    const checkoutStepStorage = getFromBrowserStorage('session', 'checkoutStep')
    if (orderFromStorageHasItems && orderHasBeenUpdated && !hasDeprecatedPaymentType) {
      onSetOrder(orderStorage)
    } else if (hasDeprecatedPaymentType || !orderStorage || (orderStorage && !orderStorage.orderId)) {
      const location = getCurrentLocation()
      const createOrderRequestBody = {
        lineItems,
        region: location.region,
        zone: parseInt(location.price_zone),
        distribution_index: parseInt(location.distribution_index),
      }

      /* check to see if we need to send a coupon to the backend during checkout */
      const hasCoupon = hasSavedCoupon()
      if (hasCoupon) {
        const couponObj = getSavedCouponObj()
        const couponType = getSavedCouponType()
        const customerCareCoupon = ['OFF', 'PER', 'DIS'].some(prefix => prefix === couponType)
        const { code: couponCode, id: couponId } = couponObj
        createOrderRequestBody.thankYouId = customerCareCoupon ? couponCode : couponId
      }

      const orderResp = await createOrder(createOrderRequestBody)
      onSetCheckoutStep('shipping')
      if (orderResp && orderResp.orderId) {
        onSetOrder(orderResp)
      }
    } else if (orderStorage && orderStorage.orderId && checkoutStepStorage !== 'review') {
      const orderResp = await updateLineItems({
        orderId: orderStorage.orderId,
        lineItems,
      })
      if (orderResp && orderResp.orderId) {
        // the updatelineItems call in checkout step 'shipping' sometimes return 0 total. If that's the case, use previous total
        if (orderResp.total === 0) {
          onSetOrder({ ...orderResp, total: tempOrder.total })
        } else {
          onSetOrder(orderResp)
        }
      }
    }
    if (order && checkoutStepStorage && checkoutStep !== checkoutStepStorage) {
      onSetCheckoutStep(Object.keys(checkoutStepStorage).length === 0 ? 'shipping' : checkoutStepStorage)
    }
    const checkoutStepsCompletedStorage = getFromBrowserStorage('session', 'checkoutStepsCompleted')
    if (order && checkoutStepsCompletedStorage && checkoutStepsCompleted !== checkoutStepsCompletedStorage) {
      onSetCheckoutStepsCompleted(checkoutStepsCompletedStorage)
    }

    // check for shipping information, contact info, and cart Items
    if (
      shippingAddress &&
      contact &&
      storeCartLineItems &&
      storeCartLineItems.length > 0 &&
      checkoutStepStorage !== 'review'
    ) {
      onSetCheckoutStep('delivery')
    }
  }

  checkPaymentType(order) {
    if (order && order.paymentInfo) {
      return order.paymentInfo.some(payment => payment.paymentType === 'CYBER')
    }
    return false
  }

  render() {
    const { order, isMobile, declineModalInfo } = this.props
    const storeCartLineItems = order && order.lineItems ? order.lineItems.filter(lineItem => lineItem.isStoreSku) : []
    const showCheckout = order && order.lineItems && order.lineItems.length > 0

    return (
      <>
        {showCheckout && (
          <section className="cell small-12 grid-x grid-margin-y checkout-page">
            <div className="cell small-12 checkout-page-header">
              <h1>CHECKOUT</h1>
            </div>
            <div
              className={`cell small-12 grid-x grid-margin-y checkout-section-steps ${
                !isMobile ? 'grid-padding-x' : ''
              }`}
            >
              <div className="checkout-section-container cell small-12 large-9 grid-x grid-margin-y">
                <div id="shipping" className="cell small-12">
                  <CheckoutStep
                    sectionTitle="Shipping Address"
                    sectionType="shipping"
                    sectionNumber="1"
                    nextSection="delivery"
                  >
                    <ShippingSection />
                  </CheckoutStep>
                </div>
                <div id="delivery" className="cell small-12">
                  <CheckoutStep
                    sectionTitle="Delivery"
                    sectionType="delivery"
                    sectionNumber="2"
                    previousSection="shipping"
                    nextSection="payment"
                  >
                    <DeliverySection storeCartLineItems={storeCartLineItems} />
                  </CheckoutStep>
                </div>
                <div id="payment" className="cell small-12">
                  <CheckoutStep
                    sectionTitle="Payment"
                    sectionType="payment"
                    sectionNumber="3"
                    previousSection="delivery"
                    nextSection="review"
                  >
                    <PaymentSection />
                  </CheckoutStep>
                </div>
                <div id="review" className="cell small-12">
                  <CheckoutStep
                    sectionTitle="Review Order"
                    sectionType="review"
                    sectionNumber="4"
                    previousSection="payment"
                  >
                    <ReviewSection />
                  </CheckoutStep>
                </div>
              </div>
              <div className={`checkout-container cell small-12 large-3 ${!isMobile ? 'grid-margin-y' : ''}`}>
                <CheckoutSticky
                  isCheckout
                  tax={order.tax}
                  deliveryCost={order.totalDeliveryCharge}
                  isMobile={isMobile}
                  storeCartLineItems={storeCartLineItems}
                />
              </div>
              <div className="cell small-12 large-9 checkout-contact">
                <CondensedContact />
              </div>
            </div>
          </section>
        )}
        {order && order.lineItems && order.lineItems.length < 1 && <CheckoutEmpty />}
        {!order && <img alt="checkout loading" src={loaderDark} />}
        <DeclineModal
          modalOpen={declineModalInfo.declineModalOpen}
          type={declineModalInfo.declineType}
          loading={declineModalInfo.declineCloseLoading}
        />
      </>
    )
  }
}

Checkout.propTypes = {
  onSetOrder: func,
  checkoutStep: string,
  onSetCheckoutStep: func,
  checkoutStepsCompleted: objectOf(bool),
  onSetCheckoutStepsCompleted: func,
  onSetCart: func,
  order: objectOf(any),
  isMobile: bool,
  declineModalInfo: objectOf(any),
}

const mapStateToProps = state => ({
  ...state.global,
  ...state.cart,
  order: state.checkout.order,
  checkoutStep: state.checkout.checkoutStep,
  checkoutStepsCompleted: state.checkout.checkoutStepsCompleted,
  declineModalInfo: state.checkout.declineModalInfo,
})

const mapDispatchToProps = dispatch => ({
  onSetCart: cart => dispatch(setCart(cart)),
  onSetOrder: order => dispatch(setOrder(order)),
  onSetCheckoutStep: checkoutStep => dispatch(setCheckoutStep(checkoutStep)),
  onSetCheckoutStepsCompleted: checkoutStepsCompleted => dispatch(setCheckoutStepsCompleted(checkoutStepsCompleted)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
