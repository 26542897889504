import React from 'react'
import { store } from '@redux/store'
import classNames from 'classnames'
import styled from 'styled-components'
import scriptLoader from 'react-async-script-loader'
import { breakPoints, colors, fonts } from '@constants/styles'
import { bool, func, number } from 'prop-types'
import getToken from '@services/cybersource'
import PaymentSvg from '@shared/svgs/paymentSvgComp'
import loadingSvg from '@assets/images/loader-dark.svg'
import ErrorBoundary from '@shared/error-boundary'
import { PrimaryButton } from '@shared/button-types'

const StyledCreditCardMicroform = styled.div`
  max-width: 559px;
  height: 340px;
  margin-top: 2em;
  border: solid 1px ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: ${breakPoints.small}) {
    margin-top: 1rem;
  }
  .microform-inner-container {
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: ${breakPoints.small}) {
      padding: 0.5rem;
    }
    .microform-header {
      max-height: 85px;
      width: 100%;
      border-bottom: 1px solid ${colors.lightGrey};
      display: flex;
      @media only screen and (max-width: ${breakPoints.small}) {
        display: block;
        text-align: center;
      }
      .payment-header {
        padding-left: 0;
        display: block;
        color: black;
        font-size: ${breakPoints.txtMediumUp};
        margin: auto;
        margin-left: 0;
        @media only screen and (max-width: ${breakPoints.small}) {
          padding: 0;
          font-size: ${breakPoints.txtMedium};
        }
      }
      svg {
        max-height: 65px;
        width: 300px;
        @media only screen and (max-width: ${breakPoints.small}) {
          width: auto;
        }
      }
    }
    .invalid-card {
      font-size: 0.85rem;
      margin: 0;
      margin-top: 0.5rem;
      color: ${colors.red};
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media only screen and (max-width: ${breakPoints.small}) {
        margin-top: 0.1rem;
        flex-grow: 1;
      }
      &.hidden {
        visibility: hidden;
      }
    }
    overflow: hidden;
    &.hidden {
      display: none;
    }
    .microform-form-container {
      flex-grow: 2;
    }
    iframe {
      height: 40px !important;
      border: 1px solid ${colors.lightGrey} !important;
      padding: 3px 5px;
      padding: 10px;
    }
    .card-input {
      width: 8rem;
      margin: 0 1rem 0 0;
      display: inline-block;
      @media only screen and (max-width: ${breakPoints.small}) {
        width: 5.5rem;
      }
      &.card-number {
        width: 100%;
      }
      label {
        font-size: ${fonts.txtMini};
      }
      input,
      select {
        width: 100%;
        display: block;
        height: 40px !important;
        border: 1px solid ${colors.lightGrey};
        padding: 10px;
      }
      select {
        font-size: 0.85rem;
      }
    }
  }
  .message {
    margin: 1rem 0;
  }
  .try-again {
    font-size: 1rem;
    color: ${colors.primary};
    &:hover {
      text-decoration: underline;
    }
  }
  .loading-icon {
    height: 100%;
    width: 100%;
    background: ${colors.lightGrey};
    background-image: url(${loadingSvg});
    background-repeat: no-repeat;
    background-position: center;
  }
  .success-icon {
    width: 3.7rem;
    height: 3.7rem;
    padding: 0.25rem;
    .checkmark {
      margin: auto;
      width: 3.1rem;
      height: 3.1rem;
      border-radius: 50%;
      display: block;
      stroke-width: 4;
      stroke: #fff;
      stroke-miterlimit: 10;
      box-shadow: inset 0 0 0 #00ab67;
      -webkit-animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
      animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
    }
    .checkmark-circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: ${colors.green};
      fill: none;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }
    .checkmark-check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
  }
  .error-icon {
    display: block !important;
    height: 50px !important;
    width: 50px !important;
    margin: 0.25rem 0 0 -0.25rem;
    left: 0 !important;
    &:before {
      background: ${colors.red};
      border-radius: 100%;
      height: 100%;
      width: 100%;
      color: ${colors.primaryInvert};
      font-size: 75px;
      top: 0;
      left: 0;
      line-height: 0.61;
      text-align: center;
    }
    &:after {
      transform: unset;
    }
    &:hover:before {
      transform: scale(1) rotate(45deg);
      font-size: 75px;
    }
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  &[disabled] {
    background-color: #d8d8d8 !important;
    color: #ffffff !important;
    border: 0px !important;
  }
`

export const SubmitButtonContainer = styled.section`
  width: 100%;
  text-align: center;
  margin: 2em 0 0;
`

export const SubmitRtgButton = styled.button`
  min-width: 9em;
  margin-right: 4%;
  margin-top: 0.5em;
  background-color: ${({ disabled }) => (disabled ? colors.disabled : colors.primary)};
  border-radius: 0;
  color: ${colors.primaryInvert};
  border: 0px;
  padding: 15px;
  text-align: center;
  box-sizing: border-box;
  font-size: ${fonts.txtMedium};
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? colors.disabled : colors.lightBlue)};
  }
  @media (max-width: ${breakPoints.small}) {
    margin-right: 0;
  }
`
class CreditCardMicroform extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      complete: false,
      errorMessage: null,
      inputError: false,
      microformIsLoading: false,
      didCreateMicroform: false,
      creditCardPaymentInfo: {},
    }
    this.formTimeout = null
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props
    const complete = this.getCreditCardDecision()
    if (complete) {
      this.setState({ complete })
    } else if (isScriptLoaded) {
      if (isScriptLoadSucceed) {
        this.setupMicroform()
      } else {
        this.onError('Something went wrong. Could not load microform script')
      }
    }
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable react/no-did-update-set-state */
    const { complete, errorMessage, microformIsLoading } = this.state
    const { isScriptLoaded, isScriptLoadSucceed } = this.props
    const newComplete = this.getCreditCardDecision()
    if (!newComplete && complete) {
      this.setState({ complete: false }, this.setupMicroform)
    }
    if (!isScriptLoaded && !microformIsLoading) {
      this.setState({ microformIsLoading: true })
    }
    if (isScriptLoaded && !prevProps.isScriptLoaded && !complete && !errorMessage) {
      if (isScriptLoadSucceed) {
        this.setupMicroform()
      } else {
        this.onError('Something went wrong. Could not load microform script')
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.formTimeout)
  }

  setupMicroform() {
    const { didCreateMicroform } = this.state
    const { onTimeout } = this.props
    const orderNumber = store.getState().orderStatus.order.OrderNumber
    if (!didCreateMicroform) {
      this.setState({ microformIsLoading: true })
      getToken({
        storeOrder: !orderNumber?.includes('i') && !orderNumber?.includes('I'),
      })
        .then(res => {
          if (res.jwk) {
            const submitCardBtn = document.querySelector('#submit-card-btn')
            window.FLEX.microform(
              {
                keyId: res.jwk.kid,
                keystore: res.jwk,
                container: '#cardNumber-container',
                label: '#cardNumber-label',
                placeholder: '',
                styles: {
                  input: {
                    'font-size': '14px',
                    color: '#555',
                  },
                  ':disabled': { cursor: 'not-allowed' },
                  valid: { color: '#008550' },
                  invalid: { color: '#eb141f' },
                },
                encryptionType: 'rsaoaep',
              },
              (setupError, microformInstance) => {
                this.setState({ microformIsLoading: false, didCreateMicroform: true })
                submitCardBtn.addEventListener('click', () => this.onSubmitCard(microformInstance))
                submitCardBtn.addEventListener('keydown', e => e.keyCode === 13 && this.onSubmitCard(microformInstance))
                this.formTimeout = setTimeout(() => {
                  this.onError('Your session timed out.')
                  onTimeout()
                }, 900000) /* Token will timeout after 15 mins */
              },
            )
          } else {
            this.onError('Something went wrong. No microform token provided')
          }
        })
        .catch(err => {
          this.onError('Something went wrong. Could not fetch microform token')
        })
    }
  }

  newCard() {
    // force remount by changing key of this microform
    const { setMicroformKey, onSubmit } = this.props
    onSubmit({
      token: '',
      authAmount: 0,
      expMonthYear: ``,
    })
    setMicroformKey()
  }

  tryAgain() {
    // force remount by changing key of this microform
    const { setMicroformKey, onSubmit } = this.props
    onSubmit({
      token: '',
      authAmount: 0,
      expMonthYear: ``,
    })
    setMicroformKey()
  }

  onSubmitCard(microformInstance) {
    const { onSubmit, authAmount } = this.props
    this.setState({ inputError: false, microformIsLoading: true })
    const cardExpirationMonth = document.querySelector('#cardExpirationMonth')
    const cardExpirationYear = document.querySelector('#cardExpirationYear')
    const options = {
      cardExpirationMonth: cardExpirationMonth.value,
      cardExpirationYear: cardExpirationYear.value,
    }

    microformInstance.createToken(options, (err, microformResponse) => {
      if (err) {
        this.setState({ inputError: true, microformIsLoading: false })
        return
      }
      this.setState({
        creditCardPaymentInfo: {
          paymentType: 'CYBERV2',
          paymentProperties: {
            token: microformResponse,
          },
        },
        microformIsLoading: false,
        complete: true,
      })
      onSubmit({
        token: microformResponse,
        authAmount: authAmount || 0,
        expMonthYear: `${cardExpirationMonth.value}${cardExpirationYear.value.substring(2)}`,
      })
    })
  }

  onError(errorMessage) {
    this.setState({
      errorMessage,
      microformIsLoading: false,
      complete: false,
    })
  }

  getCreditCardDecision() {
    const { creditCardPaymentInfo } = this.state
    return !!creditCardPaymentInfo?.paymentType
  }

  renderMonthSelection() {
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    const monthOptions = months.map(month => (
      <option value={month} key={month}>
        {month}
      </option>
    ))
    return (
      <select id="cardExpirationMonth" name="ccmonth">
        {monthOptions}
      </select>
    )
  }

  renderYearSelection() {
    const currentYear = new Date().getFullYear()
    const yearOptions = new Array(20).fill(null).map((a, index) => {
      const newYear = currentYear + index
      return (
        <option value={newYear} key={newYear}>
          {newYear}
        </option>
      )
    })
    return (
      <select id="cardExpirationYear" name="ccyear">
        {yearOptions}
      </select>
    )
  }

  render() {
    const { complete, errorMessage, microformIsLoading, inputError } = this.state
    const shouldHideForm = complete || errorMessage || microformIsLoading
    const { minimumDisabled } = this.props
    return (
      <ErrorBoundary>
        <StyledCreditCardMicroform>
          <div className={classNames('microform-inner-container', { hidden: shouldHideForm })}>
            <div className="microform-header">
              <span className="payment-header">Payment Details</span>
              <PaymentSvg
                uniqueNameForId="creditMicroForm3"
                cards={['discover', 'mastercard', 'visa', 'amex']}
                cordY="0"
              />
            </div>
            <div className={classNames('invalid-card', { hidden: !inputError })}>
              Please input valid card information.
            </div>
            <div className="microform-form-container">
              <div className="card-input card-number">
                <label id="cardNumber-label" htmlFor="cardNumber-container">
                  Card Number*
                </label>
                <div id="cardNumber-container" />
              </div>
              <div className="card-input">
                <label htmlFor="cardExpirationMonth">Expiration Month*</label>
                {this.renderMonthSelection()}
              </div>
              <div className="card-input">
                <label htmlFor="cardExpirationYear">Expiration Year*</label>
                {this.renderYearSelection()}
              </div>
            </div>
            <StyledPrimaryButton type="button" id="submit-card-btn" disabled={minimumDisabled || false}>
              Continue
            </StyledPrimaryButton>
          </div>
          {microformIsLoading && !complete && !errorMessage && <div className="loading-icon" />}
          {complete && !errorMessage && (
            <>
              <div className="success-icon">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <title>Credit card application was successful.</title>
                  <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none" />
                  <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
              </div>
              <span className="message">Credit card application was successful.</span>
              <button
                type="button"
                id="credit-try-again"
                className="try-again"
                tabIndex="0"
                value="Enter new card"
                aria-label="Enter new card"
                onClick={() => this.newCard()}
              >
                Enter new card
              </button>
              <SubmitButtonContainer>
                <SubmitRtgButton
                  type="button"
                  value="Remove Credit Card"
                  onClick={async event => {
                    event.preventDefault()
                    this.newCard()
                    if (document.getElementById('tab1')) document.getElementById('tab1').click()
                  }}
                >
                  Remove Credit Card
                </SubmitRtgButton>
              </SubmitButtonContainer>
            </>
          )}
          {!complete && errorMessage && (
            <>
              <i className="error-icon icon close" aria-label="failed to apply credit card" />
              <div className="message">
                {errorMessage}
                <button
                  type="button"
                  className="try-again"
                  tabIndex="0"
                  value="Refresh page"
                  aria-label="Refresh page"
                  onClick={this.tryAgain}
                >
                  try again.
                </button>
              </div>
            </>
          )}
        </StyledCreditCardMicroform>
      </ErrorBoundary>
    )
  }
}

CreditCardMicroform.propTypes = {
  authAmount: number,
  isScriptLoaded: bool,
  isScriptLoadSucceed: bool,
  setMicroformKey: func,
  onSubmit: func,
  onTimeout: func,
  minimumDisabled: bool,
}

CreditCardMicroform.defaultProps = {
  onSubmit: () => {},
  onTimeout: () => {},
}

export default scriptLoader([`https://flex.cybersource.com/cybersource/assets/microform/0.4.0/flex-microform.min.js`])(
  CreditCardMicroform,
)
