import React, { useState, useEffect } from 'react'
import { addToDataLayer_nextgen, removeStoreCartEvent } from '@helpers/google-tag-manager'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { get } from 'lodash'
import { fetchProductWarehouseAvailability } from '@services/product'
import { setOrder } from '@redux/modules/checkout'
import ViewMyStoreCartModal from '@shared/modals/view-my-store-cart-modal'
import { clearCart } from '@redux/modules/cart'
import { removeUnavailableItems, addToCart } from '@helpers/cart'
import { availabilityStockMessage } from '@helpers/product'
import { checkSplitElig, updateLineItems } from '@services/checkout'
import { getCurrentLocation, getRegionZone } from '@helpers/geo-location'
import CondensedContact from '@shared/condensed-contact-links'
import { LoadingSpinner } from '@components/shared/LoadingSpinner'
import PromotionContentGroup from '@shared/promotion-content-group'
import { fonts, colors } from '@constants/styles'
import { productFinancing } from '@helpers/finance'
import { clearCheckoutState } from '@helpers/checkout/global'
import { PRODUCT_CAROUSEL } from '../../@rtg2022/index'
import CartProduct from './cart-parts/cart-product'
import CartCreditCardBanner from './cart-parts/CartCreditCardBanner'
import CartEmpty from './cart-parts/cart-empty'
import CheckoutSticky from '../checkout/checkout-sticky/checkout-sticky'
import PaymentMethods from './cart-parts/payment-methods'
import StoreCart from './store-cart'
import LargeHeader from './large-header'

import '@comp-sass/cart/cart.sass'
// import { Container, Grid, Stack, Typography } from '@mui/material'
// import QuickViewModal from '../../@rtg2022/components/QuickViewModal/QuickViewModal'

const FullWidthDiv = styled.div`
  width: 100%;
`

const LoadingSpinnerWrapper = styled.div`
  height: 3rem;
  width: 100%;
`

const SplitCheckText = styled.p`
  font-weight: 600;
  font-size: ${fonts.txtMedium};
  padding: 0.5rem;
  text-align: center;
  width: 75%;
`

const SplitTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  align-items: center;
  padding: 0 0.5rem;
  border: 2px solid ${colors.darkerGreen};
`

const MobileCartCreditCardBannerWrapper = styled.div`
  width: 100vw;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
`

const userAcknowledgedToCombineStoreAndOnlineCarts = 'userAcknowledgedToCombineStoreAndOnlineCarts'

const AllCartsWrapper = ({
  cart,
  order,
  discount,
  isMobile,
  promoTargetSkus,
  region,
  setCartState,
  showPayPal,
  showApplePay,
  skusNotAvailable,
  storeCartIsLoading,
}) => {
  const dispatch = useDispatch()
  const rtg_location = useSelector(state => state.location.rtg_location)

  const [splitDeliveryElig, setSplitDeliveryElig] = useState(false)
  const [refreshComponent, setRefreshComponent] = useState(false)
  const [productAvailabilitiesBySKU, setProductAvailabilitiesBySKU] = useState(null)
  const [shouldShowFinanceText, setShouldShowFinanceText] = useState(false)
  const [onlineCartHasGiftCard, setOnlineCartHasGiftCard] = useState(false)
  const [totalFinanceAmount, setTotalFinanceAmount] = useState(0)

  const [carouselSlides, setCarouselSlides] = useState(null)

  /* determine the region the user is in to determine what products are returned with YMAL (You May Also Like) -- I don't like the abbreviation either */
  const { region: myRegion } = getRegionZone()
  const regionInPR = getRegionZone().region === 'PR'

  const query = cart.cartItems
    .filter(item => item?.product?.delivery_type !== 'T')
    .reduce((accumulator, item) => [...accumulator, item?.product?.sku], [])
    .join(',')

  useEffect(() => {
    if (!regionInPR) {
      const options = {
        query,
        categories: false,
        properties: false,
      }
      /**
       * AWS Secrets Variables
       * GATSBY_RECOMMENDATION_SERVICE: https://recommendation.rtg-dev.com (DEV), https://recommendation.rtg-prod.com (PROD)
       *
       * Categories - Defaults to all categories
       * @example https://recommendation.rtg-dev.com/youMayAlsoLike/10341737,1223550P,99035416,1014185P,1004185P?categories=accessories,lamps,lighting
       *
       * Properties - Defaults to all properties
       * @includes  sku,title,description,category,sub_category,pricing,free_shipping,catalog_availability,delivery_type,sell_individually,hasVariations,primary_image,alternate_images
       * @example https://recommendation.rtg-dev.com/youMayAlsoLike/10341737,1223550P,99035416,1014185P,1004185P?properties=sku,title,description
       *
       * Division - Set to whatever region the user is in
       * @example https://recommendation.rtg-dev.com/youMayAlsoLike/10341737,1223550P,99035416,1014185P,1004185P?division=FL
       */
      const URL = `${process.env.GATSBY_RECOMMENDATION_SERVICE}/youMayAlsoLike/${options.query}?division=${myRegion}`
      // const filterByCategory = `?categories=${options.categories}`
      // const filterByProperties = `?properties=${options.properties}`
      // const URL = base_url
      fetch(URL)
        .then(response => response.json())
        .then(json => setCarouselSlides(json))
        .catch(err => setCarouselSlides(null))
    }
  }, [query, myRegion, regionInPR])

  useEffect(() => {
    let showFinanceText = false
    let totalFinance = 0
    cart.cartItems.forEach(item => {
      if (item?.product?.delivery_type === 'T') return // no financing for gift cards
      const { showFinance, financeAmount } = productFinancing(item.price * item.quantity, item?.product?.delivery_type)
      totalFinance += financeAmount
      if (showFinance) showFinanceText = true
    })
    setTotalFinanceAmount(totalFinance)
    setShouldShowFinanceText(showFinanceText)
  }, [cart.cartItems, order.total])

  useEffect(() => {
    let hasGiftCard = false
    if (cart?.cartItems?.forEach) {
      cart.cartItems.forEach(item => {
        if (item?.product?.delivery_type === 'T') hasGiftCard = true
      })
    }
    setOnlineCartHasGiftCard(hasGiftCard)
  }, [cart.cartItems, order.total])

  const storeCartLineItems = order && order.lineItems ? order.lineItems.filter(lineItem => lineItem.isStoreSku) : []
  const storeCartHasProducts = storeCartLineItems.length > 0
  const onlineCartHasProducts = cart && cart.cartItems && cart.cartItems.length > 0
  const showViewMyStoreCartModal =
    storeCartHasProducts &&
    onlineCartHasProducts &&
    !sessionStorage.getItem(userAcknowledgedToCombineStoreAndOnlineCarts)

  const splitCheckVerbiage =
    'Some items may be available sooner than others. You may be able to split this delivery at checkout.'

  const stringedCart = JSON.stringify(order.lineItems)
  useEffect(() => {
    let fetching = false

    async function fetchSplitCheckElig() {
      const result = await checkSplitElig(order.orderId, rtg_location.zip)
      if (!fetching) setSplitDeliveryElig(result)
    }
    try {
      if (order.orderId && rtg_location.zip) {
        fetchSplitCheckElig()
      }
    } catch (error) {}
    return () => {
      fetching = true
    }
  }, [stringedCart, order.orderId, rtg_location.zip])

  useEffect(() => {
    const fetchAvailabilities = async () => {
      let availabilitiesBySKU = null

      const skus = cart.cartItems
        // get stock messages for this sku, unless its a gift card (the only online item with delivery_type 'T')
        .filter(item => item.product.delivery_type !== 'T')
        // add the product sku and any child product skus (living room sets, bedroom sets)
        .reduce(
          (accumulator, item) => [
            ...accumulator,
            item?.product?.sku,
            // todo check if we need this
            // ...(item?.product?.items_in_room || []).reduce((acc, curr) => [...acc, curr.sku], []),
          ],
          [],
        )
        .join(',')

      if (skus) {
        const { data } = await fetchProductWarehouseAvailability(
          skus,
          rtg_location.distribution_index,
          rtg_location.state,
        )
        availabilitiesBySKU = data.availabilities.reduce((acc, { sku, availableOn }) => {
          /* Sunny requested we hide all availability dates from Cart on 12/28/21 */
          // acc[sku] = availableOn
          acc[sku] = false
          return acc
        }, {})
      }

      setProductAvailabilitiesBySKU(availabilitiesBySKU)
    }

    fetchAvailabilities()
  }, [cart.cartItems, rtg_location.distribution_index, rtg_location.state])

  // remove store cart packages from the store cart
  const removeStoreCartProduct = async skuToRemove => {
    if (order && order.orderId && order.lineItems && storeCartHasProducts) {
      // if the quantity is greater then 1, reduce quantity by one
      const storeCartItemToRemove = storeCartLineItems.find(i => i.sku === skuToRemove)
      const { quantity } = storeCartItemToRemove
      let copyOfStoreCartLineItems = onlineCartHasProducts
        ? [...order.lineItems.filter(lineItem => !lineItem.isStoreSku), ...storeCartLineItems]
        : storeCartLineItems

      // // change the quantity or remove if multiple exist, otherwise, remove the product from the cart
      if (quantity > 1) {
        const copyOfItemToRemove = copyOfStoreCartLineItems.find(i => i.sku === skuToRemove)
        copyOfItemToRemove.quantity -= 1
      } else {
        copyOfStoreCartLineItems = storeCartLineItems.filter(i => i.sku !== skuToRemove)
      }

      try {
        const location = getCurrentLocation()
        const newOrder = await updateLineItems({
          orderId: order.orderId,
          lineItems: copyOfStoreCartLineItems,
          region: location.region,
          zone: parseInt(location.price_zone),
          distribution_index: parseInt(location.distribution_index),
        })
        if (newOrder && newOrder.storeCart) {
          dispatch(setOrder(newOrder))
          removeStoreCartEvent(newOrder.storeCart, storeCartItemToRemove)
        }
      } catch (error) {
        throw new Error('Could not remove The Store Cart Item')
      }
    }
  }

  const renderCartProducts = () =>
    cart.cartItems.map((item, index) => {
      const availableOn = get(productAvailabilitiesBySKU, item.sku)
      // adds stock message to all root-level cart products in cartItems
      item.product.stockMessage = availableOn
        ? availabilityStockMessage({ availableOn, product: item.product, rtg_location })
        : ''
      // adds stock message to any child products of a cart product
      if (item?.product?.items_in_room) {
        item.product.items_in_room = item.product.items_in_room.map(itm => ({
          ...itm,
          stockMessage: availabilityStockMessage({
            availableOn: get(productAvailabilitiesBySKU, itm.sku),
            product: item.product,
            rtg_location,
          }),
        }))
      }

      return (
        <div key={item.sku} className="online-cart-wrapper">
          {index === 0 && <LargeHeader text="ONLINE CART" />}
          {!regionInPR && index === 0 && splitDeliveryElig && (
            <SplitTextWrapper>
              <SplitCheckText>{splitCheckVerbiage}</SplitCheckText>
            </SplitTextWrapper>
          )}
          <CartProduct
            product={item.product}
            index={index}
            productCount={cart.cartItems.length}
            quantity={item.quantity}
            price={item.price}
            unavailableItem={skusNotAvailable.filter(prod => prod.sku === item.sku)[0]}
            activeAddons={item.activeAddons}
            warrantyEnabled={item.warrantyEnabled}
            region={region}
          />
        </div>
      )
    })

  const onCombineStoreAndOnlineCarts = () => {
    sessionStorage.setItem(userAcknowledgedToCombineStoreAndOnlineCarts, 'yes')
    setRefreshComponent(!refreshComponent)
  }

  const onClearOnlineCart = () => {
    dispatch(clearCart())
    clearCheckoutState(false, true)
  }

  return (
    <div className="cart-page">
      <div
        className={classNames('grid-x', {
          'grid-margin-x': isMobile,
        })}
      >
        <div
          className={classNames('cart-product-list-container small-12 medium-12 large-9 grid-x grid-padding-y', {
            cell: isMobile,
          })}
        >
          {/* Both Carts Are Empty Component */}
          {!storeCartHasProducts && !onlineCartHasProducts && !storeCartIsLoading && <CartEmpty />}
          {/* Store Cart Is Loading */}
          {storeCartIsLoading && (
            <LoadingSpinnerWrapper>
              <LoadingSpinner />
            </LoadingSpinnerWrapper>
          )}
          {/* Store Cart Component */}
          {storeCartHasProducts && (
            <StoreCart
              allStoreCarts={storeCartLineItems}
              removeStoreCartProduct={removeStoreCartProduct}
              storeInfo={order?.storeInfo}
            >
              {splitDeliveryElig && (
                <SplitTextWrapper>
                  <SplitCheckText>{splitCheckVerbiage}</SplitCheckText>
                </SplitTextWrapper>
              )}
            </StoreCart>
          )}
          {/* Online Cart Loading */}
          {productAvailabilitiesBySKU === null && onlineCartHasProducts && (
            <LoadingSpinnerWrapper>
              <LoadingSpinner />
            </LoadingSpinnerWrapper>
          )}
          {/* Online Cart Component */}
          {onlineCartHasProducts &&
            (productAvailabilitiesBySKU !== null || onlineCartHasGiftCard) &&
            renderCartProducts()}
          {/* Promotions Component */}
          {(onlineCartHasProducts || storeCartHasProducts) && (
            <>
              <PromotionContentGroup targetSkus={promoTargetSkus} isCart />
              <div className="cart-bottom-checkout cell small-12 grid-x">
                {!isMobile && (
                  <>
                    <div className="cell large-8 payment-methods">
                      <PaymentMethods cart={cart} width="55%" uniqueNameForId="desktopPayMethods" />
                    </div>
                    <CheckoutSticky
                      financeAmount={totalFinanceAmount}
                      cart={cart}
                      productsAvailable={skusNotAvailable.length < 1}
                      removeUnavailableItems={() => removeUnavailableItems(setCartState, skusNotAvailable)}
                      onlyButtons
                      discount={discount}
                      showPayPal={showPayPal}
                      showApplePay={showApplePay}
                      storeCartLineItems={storeCartLineItems}
                    />
                  </>
                )}
                {isMobile && (
                  <div className="cell large-8 payment-methods">
                    <PaymentMethods cart={cart} uniqueNameForId="mobilePayMethods" />
                  </div>
                )}
              </div>
            </>
          )}
          {/* You May Also Like */}
          {/*
            TRACK THESE EVENTS
            - Desktop
              - click - Previous Arrow
              - click - Next Arrow
              - Total Images
              - Image impressions
              - Is visible?
              - Tile hover => sku
              - Tile click
              - Title click
              - Price click
              - Add To Cart
            - Mobile
           */}
          {/* Credit Card Banner Component */}
          {(onlineCartHasProducts || storeCartHasProducts) && (
            <>
              {isMobile && (
                <MobileCartCreditCardBannerWrapper>
                  <CartCreditCardBanner />
                </MobileCartCreditCardBannerWrapper>
              )}
            </>
          )}
          {carouselSlides?.length > 0 && (
            <PRODUCT_CAROUSEL
              id="you-may-also-like__cart"
              title="You May Also Like"
              trigger_gtm={addToDataLayer_nextgen}
              price_zone={`${rtg_location.region}_${rtg_location.price_zone}`}
              slides={carouselSlides}
              addToCart={addToCart}
              cartItems={cart.cartItems.map(product => ({
                sku: product.sku,
                price: product.price,
                quantity: product.quantity,
              }))}
              cartTotal={order.total}
            />
          )}
          {shouldShowFinanceText && (
            <p className="financing-disclaimer cell small-12">*when paying with a Rooms To Go credit card</p>
          )}
          {/*  Added to stop from passing classes to other divs */}
          <FullWidthDiv>
            <FullWidthDiv>
              <CondensedContact />
            </FullWidthDiv>
          </FullWidthDiv>
        </div>

        {/* Your Order Checkout Component */}
        <div className="checkout-container cell small-12 large-2 grid-margin-y">
          {(onlineCartHasProducts || storeCartHasProducts) && (
            <CheckoutSticky
              financeAmount={totalFinanceAmount}
              cart={cart}
              isMobile={isMobile}
              productsAvailable={skusNotAvailable.length < 1}
              removeUnavailableItems={() => removeUnavailableItems(setCartState, skusNotAvailable)}
              rightSticky
              discount={discount}
              showPayPal={showPayPal}
              showApplePay={showApplePay}
              storeCartLineItems={storeCartLineItems}
            />
          )}
        </div>
      </div>
      <ViewMyStoreCartModal
        associateName={order?.salesPersonInfo}
        open={showViewMyStoreCartModal}
        onCombineStoreAndOnlineCarts={onCombineStoreAndOnlineCarts}
        onClearOnlineCart={onClearOnlineCart}
      />
    </div>
  )
}

AllCartsWrapper.propTypes = {
  cart: PropTypes.object,
  discount: PropTypes.number,
  isMobile: PropTypes.bool,
  order: PropTypes.object,
  promoTargetSkus: PropTypes.array,
  region: PropTypes.string,
  setCartState: PropTypes.func,
  showPayPal: PropTypes.bool,
  showApplePay: PropTypes.bool,
  skusNotAvailable: PropTypes.array,
  storeCartIsLoading: PropTypes.bool,
}

export default AllCartsWrapper
