import { store } from '@redux/store'
import {
  setOrder,
  setCheckoutStep as setCheckoutStepRedux,
  setCheckoutStepLoading,
  initialState,
  setShippingInvalidFields,
  setDeclineModalInfo,
} from '@redux/modules/checkout'
import { fetchAddressLookup, updateLineItems, updatePayment } from '@services/checkout'
import { announce } from '@helpers/aria-announce'
import { checkoutStepsOrIndex } from '@helpers/checkout/checkout'
import { scrollTo } from '@helpers/checkout/payment-section/digital-buy'
import { validateShippingStep, setAddress } from './shipping-section'
import { validateDeliveryStep, canSkipDelivery } from './delivery-section'
import { validatePaymentStep } from './payment-section/payment-section'
import { redactCCFromOrder } from './payment-section/credit-card'
import { validateReviewStep, placeOrderReview } from './review-section'
import { checkoutStepAnalytics } from '../google-tag-manager'
import { getCurrentLocation, getRegionZone } from '../geo-location'
import { getCart } from '../cart'
import { submitBillingAddress } from './payment-section/billing-address'
import { validatePoBox } from '../string-helper'

export const getOrder = () => store.getState().checkout.order

export const setOrderInfo = (info, field, isNonFinanceCredit) => {
  const order = getOrder()
  const newOrder = {
    ...order,
    isNonFinanceCredit,
    [field]: info,
  }
  store.dispatch(setOrder(newOrder))
}

export const setOrderField = (field, value) => {
  const order = getOrder()
  const newOrder = {
    ...order,
    [field]: value,
  }
  store.dispatch(setOrder(newOrder))
}

export const setCheckoutStep = async (e, checkoutStep, nextStep, placeOrder = false) => {
  const checkoutStepIndex = checkoutStepsOrIndex(checkoutStep)
  const nextStepIndex = checkoutStepsOrIndex(nextStep)
  if (checkoutStepIndex < nextStepIndex) {
    announce(`${checkoutStep} Submitted`, '', '', 0)
  }
  if (e) e.preventDefault()
  store.dispatch(setCheckoutStepLoading(true))
  const invalidFields = await validateCheckoutStep(checkoutStep, nextStep)
  if (!invalidFields || invalidFields.length < 1) {
    if (placeOrder) {
      await placeOrderReview()
    } else if (checkoutStep === 'shipping' && nextStep === 'delivery') {
      const canSkip = await canSkipDelivery()
      if (canSkip) {
        store.dispatch(setCheckoutStepRedux('payment'))
        checkoutStepAnalytics('payment', canSkip)
      } else {
        store.dispatch(setCheckoutStepRedux('delivery'))
        checkoutStepAnalytics('delivery')
      }
    } else {
      store.dispatch(setCheckoutStepRedux(nextStep))
      checkoutStepAnalytics(nextStep)
    }
    if (checkoutStep !== nextStep) {
      if (typeof window !== 'undefined') {
        const checkoutStepEl = document.querySelector(`#${nextStep}`)
        const checkoutStepHeaderEl = document.querySelector(`#Step${nextStepIndex}Header`)
        if (checkoutStepEl) {
          const topOfElement = checkoutStepEl.offsetTop - 85
          try {
            window.scroll({ top: topOfElement, behavior: 'smooth', block: 'start' })
            checkoutStepHeaderEl.focus()
          } catch {
            // do nothing
          }
        }
      }
    }
  }
  store.dispatch(setCheckoutStepLoading(false))
}

export const validateCheckoutStep = (checkoutStep, nextStep) => {
  if (checkoutStep === 'shipping') {
    return validateShippingStep(nextStep)
  }
  if (checkoutStep === 'delivery') {
    return validateDeliveryStep(nextStep)
  }
  if (checkoutStep === 'payment') {
    return validatePaymentStep(nextStep)
  }
  if (checkoutStep === 'review' && !nextStep) {
    return validateReviewStep()
  }
  return []
}

export const getRemainingTotal = (
  deliveryComplete = true,
  prevTotal = null,
  isCheckout = false,
  applyFinance = true,
  shippingComplete = true,
) => {
  const order = getOrder()
  let total = 0
  let appliedGiftCards = 0
  let appliedFinance = 0
  if (prevTotal) {
    total = prevTotal
  }
  if (order && order.total && shippingComplete && isCheckout) {
    total = order.total
    if (!deliveryComplete && order.tax) {
      total -= order.tax
    }
    if (deliveryComplete) {
      if (order.paymentInfo && order.paymentInfo.length > 0) {
        const giftCards = order.paymentInfo.filter(payment => payment.paymentType === 'GIFT')
        if (
          giftCards.length > 0 &&
          giftCards[0].authorizedAmount > 0 &&
          order.giftCardInfo &&
          order.giftCardInfo.useGiftCard
        ) {
          for (let i = 0, n = giftCards.length; i < n; i++) {
            appliedGiftCards += giftCards[i].authorizedAmount
          }
          total -= appliedGiftCards
        }
        const rtgFin = order.paymentInfo.filter(
          payment => payment.paymentType === 'FIN' || payment.paymentType === 'GEN' || payment.paymentType === 'DBUY',
        )
        if (rtgFin.length > 0 && applyFinance) {
          appliedFinance = rtgFin[0].authorizedAmount
          total -= appliedFinance
        }
      }
    }
  }
  return {
    total,
    gift: appliedGiftCards,
    fin: appliedFinance,
    tax: order && order.tax,
    deliveryCost: order && order.totalDeliveryCharge,
    totalSavings: order && order.promotions && order.promotions.totalSavings,
  }
}

export const clearCheckoutState = (clearOrderId = false, persistStoreCartLineItems = false) => {
  const order = getOrder()
  let lineItems = []
  if (order && order.lineItems && persistStoreCartLineItems) {
    lineItems = [...order.lineItems.filter(lineItem => lineItem.isStoreSku)]
  }
  const newOrder = {
    ...initialState.order,
    shippingAddress: {
      ...order.shippingAddress,
    },
    contact: {
      ...order.contact,
    },
    lineItems,
    total: 0,
    tax: 0,
    totalDeliveryCharge: 0,
    storeCart: null,
  }
  if (!clearOrderId) {
    newOrder.orderId = order.orderId
  } else {
    newOrder.orderId = null
  }
  if (newOrder.orderId) {
    const location = getCurrentLocation()
    updateLineItems({
      orderId: newOrder.orderId,
      lineItems,
      region: location.region,
      zone: parseInt(location.price_zone),
      distribution_index: parseInt(location.distribution_index),
    })
  }
  store.dispatch(setOrder(newOrder))
  store.dispatch(setCheckoutStepRedux('shipping'))
}

export const setDeclineModalClose = (type, pType) => {
  const order = getOrder()
  store.dispatch(
    setDeclineModalInfo({
      ...initialState.declineModalInfo,
    }),
  )
  updatePayment({
    orderId: order.orderId,
    paymentInfo: [],
  })
    .then(data => {
      data.selectedPaymentType = 'Credit'
      store.dispatch(setOrder(data))
      store.dispatch(setCheckoutStepRedux('payment'))
      scrollTo('payment')
    })
    .catch(error => {})
}

export const getLineItems = () => {
  const cart = getCart()
  const lineItems = []
  if (cart && cart.cartItems) {
    for (let i = 0, n = cart.cartItems.length; i < n; i++) {
      const index = lineItems.findIndex(product => product.sku === cart.cartItems[i].sku)
      if (index > -1) {
        lineItems[index].quantity += cart.cartItems[i].quantity
      } else {
        lineItems.push({
          sku: cart.cartItems[i].sku,
          quantity: parseInt(cart.cartItems[i].quantity),
          childItems: getProductChildren(cart.cartItems[i]),
          deliveryType: cart.cartItems[i].product.delivery_type,
          title: cart.cartItems[i].product.title,
          category: cart.cartItems[i].product.category,
          unitPrice: cart.cartItems[i].product.unitPrice || cart.cartItems[i].product.price,
          warrantyEnabled: cart.cartItems[i].warrantyEnabled,
          addons:
            cart.cartItems[i].activeAddons &&
            cart.cartItems[i].activeAddons.map(addon => ({
              sku: addon.sku,
              quantity: addon.quantity,
              required:
                cart.cartItems[i].product.addon_items &&
                cart.cartItems[i].product.addon_items.filter(addon_item => addon_item.sku === addon.sku)[0]
                  .addon_required,
              warrantyEnabled: addon.warrantyEnabled,
            })),
        })
      }
      if (
        !cart.cartItems[i].activeAddons ||
        (cart.cartItems[i].activeAddons && cart.cartItems[i].activeAddons.length > 0)
      ) {
        const requiredAddons =
          cart.cartItems[i].product.addon_items &&
          cart.cartItems[i].product.addon_items.filter(addon => addon.addon_required)
        if (requiredAddons) {
          for (let x = 0, y = requiredAddons.length; x < y; x++) {
            if (
              requiredAddons &&
              requiredAddons.length > 0 &&
              (!cart.cartItems[i].activeAddons ||
                (cart.cartItems[i].activeAddons &&
                  cart.cartItems[i].activeAddons.filter(addon => addon.sku === requiredAddons[x].sku).length < 1))
            ) {
              const foundIndex = lineItems.findIndex(product => product.sku === '99999925')
              if (foundIndex > -1) {
                lineItems[foundIndex].quantity += cart.cartItems[i].quantity * requiredAddons[x].quantity
              } else {
                lineItems.push({
                  sku: '99999925',
                  quantity: parseInt(cart.cartItems[i].quantity * requiredAddons[x].quantity),
                  deliveryType: 'D',
                  required: true,
                })
              }
            }
          }
        }
        if (cart.cartItems[i].activeAddons) {
          for (let x = 0, y = cart.cartItems[i].activeAddons.length; x < y; x++) {
            const foundIndex = lineItems.findIndex(product => product.sku === cart.cartItems[i].activeAddons[x].sku)
            if (foundIndex > -1) {
              lineItems[foundIndex].quantity += cart.cartItems[i].activeAddons[x].quantity
            } else {
              lineItems.push({
                sku: cart.cartItems[i].activeAddons[x].sku,
                quantity: parseInt(cart.cartItems[i].quantity * cart.cartItems[i].activeAddons[x].quantity),
                deliveryType: cart.cartItems[i].activeAddons[x].delivery_type,
                required: true,
                title: cart.cartItems[i].activeAddons[x].title,
                category: cart.cartItems[i].activeAddons[x].category,
                warrantyEnabled: cart.cartItems[i].activeAddons[x].warrantyEnabled,
              })
            }
          }
        }
      }
    }
  }
  return lineItems
}

export const getProductChildren = item => {
  let children = item.product.package_skus_v2
  const childArr = []
  if (children) {
    const { region } = getRegionZone()
    children = children[region]
    if (children) {
      for (let i = 0, n = children.length; i < n; i++) {
        childArr.push({ ...children[i], quantity: parseInt(children[i].quantity) })
      }
    }
  }
  return childArr
}

export const checkManualAddress = async (order, invalidFieldsInput, billing = false) => {
  let invalidFields = Array.from(invalidFieldsInput)
  const address = billing ? order.billingAddress : order.shippingAddress
  const validAddressEntry = address.address1 !== '' && address.address1 !== null
  const addressString = `${address.address1}${address.address2 ? ` ${address.address2}` : ''}, ${address.city} ${
    address.state
  } ${address.zip}`
  if (!order.acceptManual && validAddressEntry) {
    const manualAddressQAS = await fetchAddressLookup(addressString)
    if (manualAddressQAS && manualAddressQAS.totalMatches > 0) {
      invalidFields = await addressSuggestion({
        order,
        invalidFields: invalidFieldsInput,
        manualAddressQAS,
        billing,
        addressString,
      })
    } else if (address.zip.includes('-')) {
      const manualAddressQASNoPlus4 = await fetchAddressLookup(addressString)
      invalidFields = await addressSuggestion({
        order,
        invalidFields: invalidFieldsInput,
        manualAddressQASNoPlus4,
        billing,
        badPlus4: true,
        addressString,
      })
    } else if (manualAddressQAS && manualAddressQAS.totalMatches <= 0) {
      const manualAddressQASNoMatches = await fetchAddressLookup(addressString)
      invalidFields = await addressSuggestion({
        order,
        invalidFields: invalidFieldsInput,
        manualAddressQASNoMatches,
        billing,
        badPlus4: true,
        addressString,
      })
    } else {
      invalidFields = ['unable to verify']
    }
  }
  if (!billing) {
    if (!validatePoBox(address.address1) || !validatePoBox(address.address2)) {
      invalidFields = invalidFields.filter(value => value !== 'unable to verify')
      invalidFields = ['street', 'po box', ...invalidFields]
    }
  }
  return invalidFields
}

export const closeSuggestionModal = () => {
  const order = getOrder()
  order.suggestedAddress = null
  store.dispatch(setOrder(order))
  store.dispatch(setShippingInvalidFields([]))
}

// eslint-disable-next-line
export let addressSuggestion = async ({
  order,
  invalidFields,
  manualAddressQAS,
  billing,
  badPlus4 = false,
  addressString,
}) => {
  if (invalidFields?.length < 1) {
    if (manualAddressQAS?.results?.[0]?.suggestion) {
      const suggestion = manualAddressQAS.results[0].suggestion.toLowerCase()
      const address = billing ? order.billingAddress : order.shippingAddress
      const [suggestedAddress1, location] = suggestion.split(',')
      const [suggestedZip, suggestedState] = location.split(' ').reverse()
      const suggestedCity = location
        .split(' ')
        .reverse()
        .slice(2)
        .reverse()
        .join(' ')
        .trim()

      if (
        suggestedAddress1 !== address.address1.toLowerCase() ||
        suggestedCity !== address.city.toLowerCase() ||
        suggestedState !== address.state.toLowerCase() ||
        suggestedZip !== address.zip.toLowerCase() ||
        badPlus4
      ) {
        const suggestedAddress = address.address1 !== '' && (await fetchAddressLookup(addressString))
        if (suggestedAddress?.results?.[0]?.suggestion) {
          order.suggestedAddress = suggestedAddress.results[0].suggestion
        } else {
          order.suggestedAddress = null
        }
        return ['unable to verify']
      }
    } else {
      order.suggestedAddress = null
      return ['unable to verify']
    }
  }

  const [, globalAddressId] = manualAddressQAS?.results[0]?.format?.split('id=')
  if (globalAddressId) order.shippingAddress.globalAddressId = globalAddressId

  // TODO: remove this side-effect (requires refactor)
  store.dispatch(setOrder(order))
  return invalidFields
}

export const acceptAddressSuggestion = (suggestedAddress, billing = false, setBillingState = null) => {
  setAddress(suggestedAddress, billing)
  closeSuggestionModal()
  if (billing && setBillingState) {
    submitBillingAddress(setBillingState)
  } else {
    setCheckoutStep(null, 'shipping', 'delivery')
  }
}

export const declineAddressSuggestion = (billing = false, setBillingState = null) => {
  const order = getOrder()
  store.dispatch(setOrder({ ...order, acceptManual: true }))
  closeSuggestionModal()
  if (billing && setBillingState) {
    submitBillingAddress(setBillingState)
  } else {
    setCheckoutStep(null, 'shipping', 'delivery')
  }
}

export const isGiftCardSelected = cart =>
  cart?.cartItems?.some(
    cartItem => cartItem.product?.title === 'Gift Card' && cartItem.product?.category === 'gift-card',
  )

/**
 * Determines if we are able to offer Klarna as a payment option
 *    • The subtotal must be > $35 AFTER a gift card is applied
 *    • The subtotal must be < $10000 AFTER a gift card is applied
 *    • If the subtotal is < $35 the Klarna badge should be inactive on the page
 * @param {object} order
 * @returns {boolean}
 */
export const getKlarnaAvailability = (order, cart) => {
  if (cart && isGiftCardSelected(cart)) {
    return false
  }
  const { total, paymentInfo, amountDue } = order
  if (Object.keys(paymentInfo).length === 0 && (amountDue || total) < 35) return false
  if (Object.keys(paymentInfo).length === 0 && (amountDue || total) > 10000) return false

  const giftCardTotal = paymentInfo.filter(p => p.paymentType === 'GIFT').reduce((a, i) => a + i.authorizedAmount, 0)
  if (Object.keys(paymentInfo).length > 0 && (total - giftCardTotal < 35 || total - giftCardTotal > 10000)) return false

  return true
}
