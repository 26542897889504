import { getCurrentLocation, getRegion } from '@helpers/geo-location'
import { fetchProductBySku, fetchProductWarehouseAvailability } from '@services/product'
import ProductModel from '@models/api/products/product-model'
import { productPrice } from '@helpers/product'
import formatNumberToPrice from '@helpers/number-helper'

export const getProducts = (skus = []) => {
  const promises = []
  return new Promise(resolve => {
    skus.forEach(sku => promises.push(getProductBySkuSuccess(sku)))
    Promise.all(promises)
      .then((res = []) => {
        const products = res.filter(item => !!item)
        resolve((products || []).map(product => new ProductModel(product)))
      })
      .catch(() => {
        resolve([])
      })
  })
}

const getProductBySkuSuccess = (sku = '') => {
  const rtg_location = getCurrentLocation()
  return fetchProductBySku(sku).catch(() => Promise.resolve(undefined))
}

const getItemsInRoom = (product = null, currentRegion) => {
  if (!product) return []

  const region = currentRegion ?? getRegion()
  const itemsInRoom =
    product?.items_in_room?.[region]?.map(item => ({
      label: item?.generic_name,
      image: item?.image,
      sku: item?.sku,
      price: productPrice(item, false, region) || 0,
      quantity: item?.quantity,
    })) || []

  if (itemsInRoom.length === 0) {
    return [
      {
        label: product?.room_configurations?.[region].filter(item => item.sku === product.sku)[0].label, // our product Label(not Room Title) is not available when empty ItemsInRoom, so we jump to original array to get
        image: product?.primary_image_item,
        sku: product?.sku,
        price: productPrice(product, false, region) || 0,
        quantity: product?.quantity,
      },
    ]
  }
  return itemsInRoom
}

export const getRoomPackageUpgrades = async (product, currentLocation) => {
  const { region, state, distribution_index } = currentLocation ?? getCurrentLocation()
  const upgrades = product?.room_configurations?.[region] || []

  const skus = upgrades.map(item => item?.sku)
  if (skus.length < 1) return []

  try {
    const res = (await getProducts(skus)) ?? []
    const room_package_upgrades = []
    const availabilities = await Promise.all(
      upgrades.map(({ sku }) => fetchProductWarehouseAvailability(sku, distribution_index, state)),
    )
    upgrades.forEach((upgrade, index) => {
      const matchingProduct = res.find(item => item?.sku === upgrade?.sku)
      if (matchingProduct) {
        const itemsInRoom = getItemsInRoom(matchingProduct, region)
        const package_price = productPrice(upgrade, false, region) || 0
        const items_price_total = itemsInRoom?.reduce((acc, item) => acc + item?.price * item?.quantity, 0) || 0
        const room_savings =
          itemsInRoom.length < 1 || package_price >= items_price_total ? 0 : items_price_total - package_price
        const item = {
          sku: upgrade?.sku,
          is_featured: upgrade?.is_featured,
          title: upgrade?.title,
          sell_individually: upgrade?.sell_individually,
          pricing: upgrade?.pricing,
          route: upgrade?.route,
          price: formatNumberToPrice(package_price),
          items: itemsInRoom,
          room_savings: formatNumberToPrice(room_savings),
          // availability_date: 'Wednesday, August 07', // TODO: for PDP phase 3, make it dynamic
          availability_date: availabilities[index]?.data?.earliestAvailability ?? '',
        }
        room_package_upgrades.push(item)
      }
    })
    return room_package_upgrades
  } catch (err) {
    return []
  }
}
