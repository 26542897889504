import uuidv4 from 'uuid/v4'
import { browserName, browserVersion } from 'react-device-detect'
import Cookies from 'universal-cookie'
import { fetchProductWarehouseAvailability } from '@services/product'
import { store } from '@redux/store'
import { setDeliveryCalendar } from '@helpers/checkout/delivery-section'
import { checkoutStepsOrIndex } from '@helpers/checkout/checkout'
import { getCurrentLocation, getRegionZone } from './geo-location'
import { getCart, getProductsFromCart } from './cart'
import { getSessionId } from './cloudfront'
import {
  getProductSaleFlagType,
  productPrice,
  productOnSale,
  productAvailability,
  getWarrantyDataByRegionZone,
  earliestStockDate,
  availabilityStockMessage,
  getCustomSaleFlag,
} from './product'
import { saveLocalStorage, getFromBrowserStorage } from './storage'
import { distinct } from './string-helper'
import getDeliveryExpressAnalytics from './checkout/expressDeliveryCheck'

const cookies = new Cookies()

export const clearDataLayer = () => {
  if (window && window.google_tag_manager) {
    window.google_tag_manager['GTM-WH7CJC9'].dataLayer.reset()
  }
}

export const setupAnalytics = (options = {}) => {
  if (window && typeof window !== 'undefined' && window.dataLayer) {
    // Persisted Values
    const getOriginalLocation = window.dataLayer.find(o => o?.originalLocation)
    const originalLocation = getOriginalLocation
      ? getOriginalLocation.originalLocation
      : `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`
    const localUserID = getFromBrowserStorage('local', 'uuid')
    const userID = localUserID || saveLocalStorage('uuid', uuidv4())
    const userData = {
      id: userID,
      sessionId: getSessionId(),
      type: 'customer',
      productType: 'furniture',
      location: getCurrentLocation(),
      browser: {
        name: browserName,
        version: browserVersion,
      },
      environment: process.env.GATSBY_ENV_SHORT,
    }
    const analyticsProducts = []
    const cart = getCart()
    if (cart && cart.lineItems) {
      Object.entries(cart.cartItems).forEach(([item, cartItem]) => {
        if (cartItem && cartItem.activeAddons) {
          cartItem.product.active_addons = cartItem.activeAddons
        }
        analyticsProducts.push(analyticsProduct(cartItem.product, cartItem.quantity, item))
      })
    }
    const user = {
      user: userData,
      cart: {
        products: analyticsProducts,
      },
    }
    const pageData = {
      event: 'pageData',
      page: options.pageData,
      originalLocation,
    }

    // Set Data Layer for GTM
    clearDataLayer()
    window.dataLayer?.push(user)
    window.dataLayer?.push(pageData)
  }
}

export const addToDataLayer = (event, category, action, label, value) => {
  if (window && window.dataLayer) {
    window.dataLayer?.push({
      event,
      interaction: {
        category,
        action,
        label,
        value,
      },
    })
  }
}

/**
 * Per Brian Donegan's request, we are going to discontinue passing category and action
 * and provide label and value as key/value pairs.  Legacy method will be breaking in the future,
 * but unknown timeline.
 * @param {string} event
 * @param {object} data
 */
export const addToDataLayer_nextgen = (event, data) => {
  if (window && window.dataLayer) {
    window.dataLayer?.push({ event, ...data })
  }
}

export const trackImpression = (inView, contentIndex, content, isPLPBanner = false) => {
  if (inView && window && (content.__typename === 'ContentfulBanner' || content.__typename === 'ContentfulBannerV3')) {
    window.dataLayer?.push({
      event: 'ee_promoView',
      ecommerce: {
        promoView: {
          contentIndex,
          banner: content.name || content.title,
          isPLPBanner,
        },
      },
    })
  }
}

export const strapi_trackImpression = (contentIndex, title, isPLPBanner = false) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer?.push({
      event: 'ee_promoView',
      ecommerce: {
        promoView: {
          contentIndex,
          banner: title,
          isPLPBanner,
        },
      },
    })
  }
}

export const analyticsProduct = (product, quantity, position, warrantyEnabled = false) => {
  if (product) {
    const state = store.getState()
    const {
      product: { stockMessage },
    } = state
    const warrantyData =
      warrantyEnabled && product?.warranty_pricing
        ? getWarrantyDataByRegionZone(product.warranty_pricing, getRegionZone())
        : product?.warrantyPrice
    const warrantyPrice = warrantyData ? warrantyData.price : null
    const earliestStockDateValue = earliestStockDate(product)
    const custom_sale_flag = getCustomSaleFlag(product)
    return {
      product_flag:
        custom_sale_flag?.text || getProductSaleFlagType({ product, availabilityDate: earliestStockDateValue }),
      custom_sale_flag,
      earliestStockDate: earliestStockDateValue,
      title: product?.title,
      sku: product?.sku,
      price: productPrice(product),
      unitPrice: product?.unitPrice,
      quantity: product?.quantity || quantity,
      position,
      brand: product?.brand,
      catalog: product?.catalog,
      category: product?.category,
      stockMessage: product?.stockMessage || stockMessage,
      sub_category: product?.sub_category,
      generic_name: product?.generic_name,
      gaCategory: `${product?.catalog}/${product?.category}/${product?.sub_category}/${product?.generic_name ||
        product?.title}`,
      collection: product?.collection,
      delivery_type: product?.delivery_type,
      type: product?.type,
      room_type_code: product?.room_type_code,
      piececount: product?.piececount,
      sell_individually: product?.sell_individually,
      single_item_room: product?.single_item_room,
      free_shipping: product?.free_shipping,
      on_sale: productOnSale(product),
      availability: productAvailability(product),
      dimensions: product?.dimensions,
      decor: product?.decor,
      style: product?.style,
      color_family: product?.color_family,
      material_family: product?.material_family,
      finish_family: product?.finish_family,
      movement: product?.movement,
      size: product?.size,
      age: product?.age,
      gender: product?.gender,
      primary_image: product?.primary_image,
      active_addons: product?.active_addons,
      addon_items: product?.addon_items,
      variations: product?.variations,
      upgrade_items: product?.upgrade_items,
      room_configurations: product?.room_configurations,
      package_skus_v2: product?.package_skus_v2,
      warrantyEnabled,
      warrantyPrice: warrantyEnabled ? warrantyPrice : null,
      route: product?.route,
    }
  }
  return null
}

export const getPaymentTypesForAnalytics = order => {
  const paymentTypes = []
  let gcAmount = 0
  let giftCard = false
  if (order.paymentInfo && order.paymentInfo.length > 0) {
    order.paymentInfo.forEach(payment => {
      let type = ''
      switch (payment.paymentType) {
        case 'CYBERV3':
          type = 'Credit Card'
          break
        case 'FIN':
          type = 'RTG Financing'
          break
        case 'PALV2':
          type = 'PayPal'
          break
        case 'VISA':
          type = 'Visa Checkout'
          break
        case 'AFF':
          type = 'Affirm'
          break
        case 'GIFT':
          giftCard = true
          gcAmount += payment.authorizedAmount
          break
        case 'GEN':
          type = 'Genesis'
          break
        case 'APP':
          type = 'Apple Pay'
          break
        case 'DBUY':
          type = 'RTG Financing'
          break
        case 'KLN':
          type = 'Klarna'
          break
        case 'BIT':
          type = 'bitpay'
          break
        default:
          break
      }
      if (payment.paymentType !== 'GIFT') {
        paymentTypes.push({ type, amount: payment.authorizedAmount })
      }
    })

    if (giftCard) paymentTypes.push({ type: 'Gift Card', amount: gcAmount })
  }
  return paymentTypes
}

export const getProductCatalogs = products => {
  const catalogList = Object.values(products).reduce((list, value) => {
    list.push(value.catalog)
    return list
  }, [])
  return catalogList.filter(distinct)
}

export const setupCheckoutAnalytics = async (cart, order) => {
  await setupAnalytics({
    pageData: {
      type: 'checkout',
      title: 'Checkout',
      path: '/checkout',
    },
  })
  const analyticsProducts = await getAnalyticsProducts(cart)

  window.dataLayer?.push({
    event: 'ee_checkout',
    ecommerce: {
      checkout: {
        products: analyticsProducts,
        currentStoreCartItems: order.lineItems && order.lineItems.filter(lineItem => lineItem.isStoreSku),
        storeCartObject: order.storeCart,
      },
    },
  })
  checkoutStepAnalytics('shipping')
}

export const checkoutStepAnalytics = (nextStep, deliverySkip) => {
  if (deliverySkip) {
    window.dataLayer?.push({
      event: 'ee_checkout_option',
      ecommerce: {
        checkout_option: {
          step: checkoutStepsOrIndex('delivery'),
        },
      },
    })
  }
  window.dataLayer?.push({
    event: 'ee_checkout_option',
    ecommerce: {
      checkout_option: {
        step: checkoutStepsOrIndex(nextStep),
      },
    },
  })
}

export const getAnalyticsProducts = async cartInput => {
  const cart = cartInput || getCart()
  const cartItems = cart && cart.cartItems
  const products = await getProductsFromCart(cart)
  const location = getCurrentLocation()

  return Promise.all(
    products.map(async product => {
      const productWarehouseAvailability = await fetchProductWarehouseAvailability(
        product.sku,
        location.distribution_index,
        location.state,
      )
      const availStockMessage = availabilityStockMessage({
        availableOn: productWarehouseAvailability.data.earliestAvailability,
        product,
        rtg_location: location,
      })
      const index = cartItems.findIndex(item => item.sku === product.sku)
      if (cartItems && cartItems[index] && cartItems[index].activeAddons && cartItems[index].activeAddons.length > 0) {
        product.active_addons = cartItems[index].activeAddons.map(addon => analyticsProduct(addon, addon.quantity || 1))
      }
      const quantity = product.quantity ? product.quantity * cartItems[index].quantity : cartItems[index].quantity
      return analyticsProduct(
        { ...product, stockMessage: availStockMessage },
        quantity,
        index,
        cartItems[index].warrantyEnabled,
      )
    }),
  )
}

export const updateDeliveryAnalytics = data => {
  const {
    isPickup,
    doorwayDelivery,
    deliveryDate,
    deliveryCalendar: deliveryDates,
    pickupCalendar,
    expressCalendar,
  } = data
  const deliveryCalendar = setDeliveryCalendar(deliveryDates, pickupCalendar, expressCalendar)
  let deliveryOption = 'delivery'
  if (isPickup) {
    deliveryOption = 'pickup'
  } else if (doorwayDelivery) {
    deliveryOption = 'doorway'
  }
  window.dataLayer?.push({
    event: 'updateDelivery',
    deliveryOption,
    deliveryDate,
    deliveryExpress: getDeliveryExpressAnalytics({ data, deliveryCalendar }),
  })
}

export const placeOrderAnalytics = async (res, isThankyouCouponOrder, order, deliveryMode) => {
  const deliveryCalendar = setDeliveryCalendar(order.deliveryCalendar, order.pickupCalendar, order.expressCalendar)
  const analyticsProducts = await getAnalyticsProducts()
  const paymentTypes = getPaymentTypesForAnalytics(order)
  window.dataLayer?.push({
    event: 'ee_purchase',
    ecommerce: {
      purchase: {
        id: order.orderId,
        catalog: getProductCatalogs(analyticsProducts),
        revenue: order.total - order.tax - order.totalDeliveryCharge,
        tax: order.tax,
        shipping: order.totalDeliveryCharge,
        coupon: isThankyouCouponOrder ? 'thank you coupon' : undefined,
        deliveryDate: order.deliveryDate,
        region: getCurrentLocation().region,
        deliveryOption: deliveryMode,
        shippingState: order.shippingAddress.state,
        paymentTypes,
        products: analyticsProducts,
        promotions: order.promotions || null,
        currentStoreCartItems: order.lineItems && order.lineItems.filter(lineItem => lineItem.isStoreSku),
        storeCartObject: order.storeCart,
        deliveryExpress: getDeliveryExpressAnalytics({ data: order, deliveryCalendar }),
        deliverySplit: getSplitStatus(order),
      },
    },
  })
}

const getSplitStatus = ({ splitCalendar, splitDeliveryDates }) => {
  if (splitDeliveryDates) {
    return 'split'
  }
  if (splitCalendar && !splitDeliveryDates) {
    return 'not split'
  }
  if (!splitCalendar && !splitDeliveryDates) {
    return 'ineligible'
  }
  return undefined
}

export const addStoreCartEvent = storeCartObject => {
  if (window) {
    window.dataLayer?.push({
      event: 'ee_storecart_add',
      ecommerce: {
        add: {
          storeCartObject,
        },
      },
    })
  }
}

export const removeStoreCartEvent = (storeCartObject, removedItem) => {
  if (window) {
    window.dataLayer?.push({
      event: 'ee_remove',
      ecommerce: {
        remove: {
          storeCartObject,
          removedItem,
        },
      },
    })
  }
}

export const suggestionMissEvent = (query, exit) => {
  if (window && typeof window !== 'undefined') {
    window.dataLayer?.push({
      event: 'search_exit',
      search_type: exit ? 'exit' : 'clear',
      typed_keyword: query,
    })
  }
}

export const suggestionPassEvent = ({ query, suggested, suggestion, index }) => {
  if (window && typeof window !== 'undefined') {
    if (suggested) {
      window.dataLayer?.push({
        event: 'search_submit',
        search_type: 'suggested',
        typed_keyword: query,
        suggested_keyword: suggestion,
        suggested_position: index,
      })
    } else {
      window.dataLayer?.push({
        event: 'search_submit',
        search_type: 'typed',
        typed_keyword: query,
      })
    }
  }
}

export const orderStatusEvent = (
  event,
  {
    // destructured properties of order object, defaulted to undefined
    OrderNumber = undefined,
    Status = undefined,
    DeliveryDate = undefined,
    OrderDate = undefined,
    DoorwayDelivery = undefined,
    Balance = undefined,
    OrderTotal = undefined,
  },
) => {
  if (window?.dataLayer) {
    window.dataLayer?.push({
      event,
      orderStatus: {
        OrderNumber,
        Status,
        DeliveryDate,
        OrderDate,
        DoorwayDelivery,
        Balance,
        OrderTotal,
      },
    })
  }
}
