import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { getStateEligiblity } from '@helpers/extendedServiceContract'
import InfoModal from '@shared/info-modal'
import { setStockMessage } from '@redux/modules/product'
import { graphql, StaticQuery } from 'gatsby'
import StrapiHtml from '@templates/strapi-cms/content-types/HTML'
import { getCurrentLocation, getRegionZone } from '@helpers/geo-location'
import {
  productPrice,
  getRequiredAddon,
  availabilityStockMessage,
  getWarrantyDataByRegionZone,
  getWarrantyModalByProvider,
} from '@helpers/product'
import { addToCart, getCartItemQuantity, cartProduct } from '@helpers/cart'
import { analyticsProduct } from '@helpers/google-tag-manager'
import { fetchPromotions } from '@services/checkout'
import { HOME_PAGE, PLP } from '@constants/page-names'
import { fetchProductBySku, fetchProductWarehouseAvailability } from '@services/product'
import styled from 'styled-components'
import { AddonModelPropType } from '@models/api/products/addon-model'
import WarrantyModalTemplate from '@shared/modals/warranty-modal'
import AddToCartStockMessage from './add-to-cart-stock-message'
import AddToCartRequiredAddons from './add-to-cart-required-addons'
import AddToCartModalProducts from './add-to-cart-modal-products'
import AddToCartAddons from './add-to-cart-addons'
import AddToCartCheckmark from './add-to-cart-checkmark'
import AddToCartPromoAddons from './add-to-cart-promo-addons'
import AddToCartModalButtons from './add-to-cart-modal-buttons'
import CloseModal from '../../shared/modals/close-modal'
import '@comp-sass/product/product-parts/add-to-cart-modal.sass'

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')

const ScrollContainer = styled.div`
  height: auto;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`

class AddToCartModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reachedCartLimit: false,
      includesRequiredAddon: false,
      requiredAddons: null,
      allActiveAddons: null,
      requiredSelected: null,
      reqConfirmed: false,
      reqAddedToCart: false,
      discount: 0,
      showInfoModal: false,
      showWarrantyModal: false,
      isWarrantyEnabled: props.warrantyEnabled || false,
      location: getCurrentLocation(),
      stockMessage: '',
    }
  }

  componentDidMount() {
    this.setUp()
  }

  componentDidUpdate(prevProps) {
    const { addons } = this.props
    if (prevProps.addons !== addons && addons) {
      this.setUp()
    }
  }

  stockCheck(message, bypassRegion) {
    return bypassRegion
      ? message !== 'Discontinued ' && message !== 'Available Soon ' && message !== 'Out of Stock '
      : message !== 'Not available in your region ' &&
          message !== 'Discontinued ' &&
          message !== 'Available Soon ' &&
          message !== 'Out of Stock '
  }

  setUp = () => {
    const { pageName = '', stockMessage, product, onSetStockMessage } = this.props
    const { location } = this.state
    this.setState({
      stockMessage,
    })
    const includesRequiredAddon = this.includesRequiredAddon()
    if (!stockMessage && product.sku !== '83333333') {
      if (
        location &&
        product &&
        (location.region !== 'OOM' ||
          (location?.region === 'OOM' && [HOME_PAGE, PLP].includes(pageName)) ||
          ((product.delivery_type === 'O' || product.delivery_type === 'U') && location.region === 'OOM'))
      ) {
        fetchProductWarehouseAvailability(product.sku, location.distribution_index, location.state)
          .then(({ data }) => {
            const availStockMessage = availabilityStockMessage({
              availableOn: data.earliestAvailability,
              product,
              rtg_location: location,
              setStockMessage: onSetStockMessage,
            })

            this.setState({ stockMessage: availStockMessage })
            if (this.stockCheck(availStockMessage, true) && !includesRequiredAddon) {
              this.addToCartAttempt()
            }
          })
          .catch(() => {
            onSetStockMessage('Out of Stock ')
          })
      } else if (product.delivery_type !== 'O' && product.delivery_type !== 'U' && location.region === 'OOM') {
        // TODO: This catch and call to onSetStoreMessage does not work on PLP page
        onSetStockMessage('Not available in your region')
      } else {
        onSetStockMessage(stockMessage)
        if (this.stockCheck(stockMessage, true) && !includesRequiredAddon) {
          this.addToCartAttempt()
        }
      }
    } else {
      onSetStockMessage(stockMessage)
      if (this.stockCheck(stockMessage) && !includesRequiredAddon) {
        this.addToCartAttempt()
      }
    }
  }

  includesRequiredAddon = () => {
    const { addons, requiredSelected, activeAddons } = this.props
    const { region } = getRegionZone()
    let includesRequiredAddon = false
    if (addons && (requiredSelected === null || typeof requiredSelected === 'undefined')) {
      const requiredAddons = addons.filter(
        addon => addon.addon_required && addon.catalog_availability && addon.catalog_availability[region],
      )
      includesRequiredAddon = requiredAddons.length > 0 && !activeAddons.includes(requiredAddons[0])
      this.setState({
        includesRequiredAddon,
        requiredAddons: requiredAddons.length > 0 ? requiredAddons : null,
      })
    }
    return includesRequiredAddon
  }

  onAddRequiredAddon = requiredAddon => {
    const { activeAddons } = this.props
    const { requiredAddons, requiredSelected } = this.state
    const allActiveAddons = [...activeAddons, ...requiredAddons.filter(addon => requiredAddon.skus.includes(addon.sku))]
    this.addToCartAttempt(requiredSelected && allActiveAddons)
    this.setState({ allActiveAddons: requiredSelected && allActiveAddons, reqAddedToCart: true })
  }

  checkForSliderTitle = (product, source) => {
    let sliderTitle = ''
    if (product && product.sliderTitle) sliderTitle = `${product.sliderTitle}`
    else if (source) sliderTitle = `${source.replace(/[-_]/g, ' ')}`
    return sliderTitle
  }

  addToCartAttempt = async (allActiveAddons = null) => {
    const {
      product,
      index,
      quantity,
      activeAddons,
      promoItems,
      promoDescription,
      promoQualifierQuantity,
      promoTargetQuantity,
      source,
    } = this.props
    const { isWarrantyEnabled } = this.state
    let newActiveAddons = activeAddons

    // check if warranty is enabled by props or state and send this
    if (allActiveAddons) {
      newActiveAddons = allActiveAddons
    }
    const cartItemQuantity = getCartItemQuantity(product.sku)
    if (cartItemQuantity === 10) {
      this.setState({
        reachedCartLimit: true,
      })
    } else {
      let finalActiveAddons = null
      if (newActiveAddons && newActiveAddons.length > 0) {
        finalActiveAddons = newActiveAddons
      }
      let reducedActiveAddons = []
      if (finalActiveAddons && finalActiveAddons.length > 0) {
        for (let i = 0, n = finalActiveAddons.length; i < n; i++) {
          reducedActiveAddons.push(cartProduct(finalActiveAddons[i]))
        }
      } else {
        reducedActiveAddons = null
      }
      if (promoItems && promoTargetQuantity && promoQualifierQuantity) {
        const requestItems = [
          {
            sku: product.sku,
            quantity: parseInt(promoQualifierQuantity),
          },
        ]
        promoItems.map(item =>
          requestItems.push({
            sku: item.sku,
            quantity: parseInt(promoTargetQuantity),
          }),
        )
        const { region, zone } = getRegionZone()
        fetchPromotions({
          requestItems,
          region,
          zone,
        }).then(data => {
          this.setState({
            discount: data.totalSavings || 0,
          })
        })
        for (let x = 0; x < promoQualifierQuantity; x++) {
          addToCart(product, productPrice(product), reducedActiveAddons, isWarrantyEnabled)
        }

        for (let i = 0, n = promoItems.length; i < n; i++) {
          for (let x = 0; x < promoTargetQuantity; x++) {
            addToCart(promoItems[i], 0, null, isWarrantyEnabled)
          }

          fetchProductBySku(promoItems[0].sku)
            .then(result => {
              const { stockMessage } = this.state
              window.dataLayer.push({
                event: 'ee_add',
                ecommerce: {
                  add: {
                    position: index,
                    promoDescription,
                    list: this.checkForSliderTitle(product, source),
                    products: [
                      analyticsProduct({ ...product, stockMessage }, promoTargetQuantity, null, isWarrantyEnabled),
                      analyticsProduct({ ...result, stockMessage }, promoTargetQuantity, null, isWarrantyEnabled),
                    ],
                  },
                },
              })
            })
            .catch(() => {
              window.dataLayer.push({
                event: 'ee_add',
                ecommerce: {
                  add: {
                    position: index,
                    promoDescription,
                    list: this.checkForSliderTitle(product, source),
                    products: [analyticsProduct(product, promoTargetQuantity, null, isWarrantyEnabled), promoItems[0]],
                  },
                },
              })
            })
        }
      } else {
        addToCart(product, productPrice(product), reducedActiveAddons, isWarrantyEnabled, quantity)
        const { location } = this.state
        fetchProductWarehouseAvailability(product.sku, location.distribution_index, location.state)
          .then(({ data }) => {
            const availStockMessage = availabilityStockMessage({
              availableOn: data.earliestAvailability,
              product,
              rtg_location: location,
            })

            if (allActiveAddons) this.setState({ reqAddedToCart: true })
            product.active_addons = finalActiveAddons
              ? finalActiveAddons.map(p => analyticsProduct(p, p.quantity || 1), null, isWarrantyEnabled)
              : []
            window.dataLayer.push({
              event: 'ee_add',
              ecommerce: {
                add: {
                  position: index,
                  list: this.checkForSliderTitle(product, source),
                  products: [
                    analyticsProduct({ ...product, stockMessage: availStockMessage }, 1, null, isWarrantyEnabled),
                  ],
                },
              },
            })
          })
          .catch(() => {})
      }
    }
  }

  onChangeRequiredAddons = value => {
    this.setState({
      requiredSelected: value,
      reqConfirmed: true,
    })
  }

  showInfoModalChange = () => this.setState({ showInfoModal: true })

  toggleWarrantyEnabled = event => {
    const isChecked = event.target.checked
    setTimeout(() => {
      this.setState({ isWarrantyEnabled: isChecked })
    }, 0)
  }

  shouldShowWarrantyModal = () => {
    this.setState({ showWarrantyModal: true })
  }

  closeWarrantyModal = () => {
    this.setState({ showWarrantyModal: false })
  }

  closeBunkieModal = () => {
    this.setState({ showInfoModal: false })
  }

  render() {
    const {
      modalOpen,
      product,
      closeModal,
      activeAddons,
      promoDescription,
      promoItems,
      promoQualifierQuantity,
      promoTargetQuantity,
      promoStrikethroughPrice,
    } = this.props
    const {
      stockMessage,
      reachedCartLimit,
      requiredAddons,
      includesRequiredAddon,
      requiredSelected,
      reqConfirmed,
      reqAddedToCart,
      allActiveAddons,
      discount,
      showInfoModal,
      showWarrantyModal,
      isWarrantyEnabled,
    } = this.state

    const requiredAddon = getRequiredAddon(requiredAddons)
    const isStateEligible = getStateEligiblity()

    const regionZoneData = getRegionZone()
    const warrantyData = product.warranty_pricing
      ? getWarrantyDataByRegionZone(product.warranty_pricing, regionZoneData)
      : product.warrantyPrice
    const warrantyInfo = {
      isWarrantyEnabled,
      warrantyData,
    }
    const WarrantyModal = warrantyData ? WarrantyModalTemplate : null

    return (
      <>
        <Modal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          contentLabel="Add To Cart Modal"
          className="add-to-cart-modal"
          overlayClassName="modal-overlay"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div className="card grid-x grid-margin-x">
              <ScrollContainer>
                {(!includesRequiredAddon || reqAddedToCart) && !reachedCartLimit && this.stockCheck(stockMessage) && (
                  <AddToCartCheckmark promoDescription={promoDescription} promoItems={promoItems} discount={discount} />
                )}
                {includesRequiredAddon && !reqAddedToCart && !reachedCartLimit && this.stockCheck(stockMessage) && (
                  <h3 className="unable black">Please accept or decline required add-ons.</h3>
                )}
                <AddToCartStockMessage
                  includesRequiredAddon={includesRequiredAddon}
                  reachedCartLimit={reachedCartLimit}
                  stockMessage={stockMessage}
                />
                {includesRequiredAddon && !reqAddedToCart && requiredAddon && (
                  <AddToCartRequiredAddons
                    requiredAddon={requiredAddon}
                    requiredSelected={requiredSelected}
                    onChangeRequiredAddons={this.onChangeRequiredAddons}
                    showInfoModalChange={this.showInfoModalChange}
                  />
                )}
                <div className="add-to-cart-modal-products cell small-12">
                  {product && (
                    <AddToCartModalProducts
                      warrantyInfo={warrantyInfo}
                      product={product}
                      promoQualifierQuantity={promoQualifierQuantity}
                      reachedCartLimit={reachedCartLimit}
                      stockMessage={stockMessage}
                      includesRequiredAddon={includesRequiredAddon}
                      toggleWarrantyEnabled={this.toggleWarrantyEnabled}
                      showWarrantyModal={this.shouldShowWarrantyModal}
                      addToCart={addToCart}
                      isStateEligible={isStateEligible}
                    />
                  )}
                  {((allActiveAddons && allActiveAddons.length > 0) || (activeAddons && activeAddons.length > 0)) && (
                    <AddToCartAddons allActiveAddons={allActiveAddons} activeAddons={activeAddons} />
                  )}
                  {promoItems && promoItems.length > 0 && (
                    <AddToCartPromoAddons
                      promoItems={promoItems}
                      promoStrikethroughPrice={promoStrikethroughPrice}
                      shouldShowStrikethroughPrice={product?.strikethrough?.[getRegionZone().region] || false}
                      promoTargetQuantity={promoTargetQuantity}
                      discount={discount}
                    />
                  )}
                </div>
                <AddToCartModalButtons
                  closeModal={closeModal}
                  includesRequiredAddon={includesRequiredAddon}
                  reqAddedToCart={reqAddedToCart}
                  reqConfirmed={reqConfirmed}
                  onAddRequiredAddon={this.onAddRequiredAddon}
                  requiredAddon={requiredAddon}
                  stockMessage={stockMessage}
                  isWarrantyEnabled={isWarrantyEnabled}
                  product={product}
                />
                <CloseModal closeModal={closeModal} />
              </ScrollContainer>
            </div>
          </div>
          {showInfoModal && (
            <InfoModal
              label="What is a Bunkie Board?"
              btnClass="bunkie-board"
              mdlClass="rtg-bunkie-board-modal"
              shouldShowModal={showInfoModal}
              closeModal={this.closeBunkieModal}
            >
              <StaticQuery
                query={graphql`
                  query ModalInfoQuery2 {
                    strapiHtml(Title: { eq: "HTML - Bunkie Board Modal" }) {
                      ...StrapiHtmlFragment
                    }
                  }
                `}
                render={data => <StrapiHtml data={data.strapiHtml} />}
              />
            </InfoModal>
          )}
        </Modal>
        {WarrantyModal && (
          <WarrantyModal
            shouldShowModal={showWarrantyModal}
            closeModal={this.closeWarrantyModal}
            region={warrantyData.provider_name}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({ ...state.cart })

const mapDispatchToProps = dispatch => ({
  onSetStockMessage: message => dispatch(setStockMessage(message)),
})

AddToCartModal.propTypes = {
  pageName: PropTypes.string,
  product: PropTypes.object,
  index: PropTypes.number,
  activeAddons: PropTypes.arrayOf(AddonModelPropType),
  promoItems: PropTypes.array,
  promoDescription: PropTypes.string,
  promoQualifierQuantity: PropTypes.number,
  promoTargetQuantity: PropTypes.number,
  warrantyEnabled: PropTypes.bool,
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  promoStrikethroughPrice: PropTypes.number,
  stockMessage: PropTypes.string,
  onSetStockMessage: PropTypes.func,
  requiredSelected: PropTypes.arrayOf(AddonModelPropType),
  addons: PropTypes.array,
  source: PropTypes.string,
  quantity: PropTypes.bool,
}

// eslint-disable-next-line prettier/prettier
export default connect(mapStateToProps, mapDispatchToProps)(AddToCartModal)
