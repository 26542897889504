import React, { useEffect, useState } from 'react'
import { find } from 'lodash'
import PropTypes from 'prop-types'
import InfoModal from '@shared/info-modal'
import RTGLink from '@shared/link'
import { productUrl } from '@helpers/route'
import styled from 'styled-components'
import { analyticsProduct } from '@helpers/google-tag-manager'
import { fetchProductBySku } from '@services/product'

const Wrapper = styled.div`
  padding-top: 1rem;
`

const AddonDetailsModal = ({
  addon,
  shouldShowModal = false,
  product = null,
  cartIndex = 0,
  region = null,
  onCloseModal = () => {},
}) => {
  const [formattedAnalyticsProduct, setFormattedAnalyticsProduct] = useState(null)
  const [description, setDescription] = useState('')

  useEffect(() => {
    const setupDescription = () => {
      fetchProductBySku(addon?.sku)
        .then(res => setDescription(res?.description || ''))
        .catch(() => {})
    }

    const setupAnalytics = () => {
      try {
        const formatted = product?.addon_items
          .filter(item => item?.catalog_availability && !!item?.catalog_availability[region])
          .map(item => analyticsProduct(item, 1, cartIndex + 1))

        setFormattedAnalyticsProduct(formatted)
      } catch (error) {}
    }

    if (addon?.sku) {
      setupDescription()
      setupAnalytics()
    }
  }, [addon.sku, cartIndex, product.addon_items, product.sku, region])

  return (
    <InfoModal
      label="Addon Details"
      mdlClass="rtg-addon-details-modal"
      shouldShowModal={shouldShowModal}
      closeModal={onCloseModal}
    >
      <div className="contentful-markdown">
        <h1>
          <strong>{addon?.title}</strong>
        </h1>
        <Wrapper>
          <img src={addon?.image} alt={`sku: ${addon?.sku}`} />
        </Wrapper>
        <Wrapper>sku: {addon?.sku}</Wrapper>
        <div>
          <RTGLink
            data={{
              slug: productUrl(addon?.route),
            }}
            trackingData={{
              event: 'ee_click',
              ecommerce: {
                click: {
                  list: 'addon_in_cart',
                  position: cartIndex + 1,
                  products: [find(formattedAnalyticsProduct, { sku: addon?.sku })],
                },
              },
            }}
          >
            <span className="info-link">See all details</span>
          </RTGLink>
        </div>
        <h2 style={{ paddingTop: '1rem' }}>
          <strong>Description</strong>
        </h2>
        <p>{description}</p>
      </div>
    </InfoModal>
  )
}

AddonDetailsModal.propTypes = {
  addon: PropTypes.object,
  shouldShowModal: PropTypes.bool,
  product: PropTypes.object,
  cartIndex: PropTypes.number,
  region: PropTypes.string,
  onCloseModal: PropTypes.func,
}

export default AddonDetailsModal
