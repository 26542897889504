import React from 'react'
import { objectOf, any, bool, object, func } from 'prop-types'
import classNames from 'classnames'
import { store } from '@redux/store'
import scriptLoader from 'react-async-script-loader'
import { getFinancePlansFromRegion } from '@helpers/finance'
import { fetchClientToken, fetchClientTokenSatus } from '@services/digital-buy'
import { decryptFinancePlans, scrollTo } from '@helpers/checkout/payment-section/digital-buy'
import { updateAddress, updatePayment } from '@services/checkout'
import { setOrder, setCheckoutStep } from '@redux/modules/checkout'
import { getAddressSpecificBody } from '@helpers/checkout/shipping-section'
import { getOrder } from '@helpers/checkout/global'
import { addToDataLayer, checkoutStepAnalytics } from '@helpers/google-tag-manager'
import AlertModal from '@shared/alert-modal'
import loaderLight from '../../../../assets/images/loader-light.svg'
import { DigitalBuyWrapper, SubmitRtgButton, LoadingSpinner } from './styles'

export class DigitalBuy extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clicked: false,
      unavailableButton: true,
      enableButton: false,
      clientToken: null,
      financeCode: '600',
      currentPlan: null,
      currentTotal: 0,
      processInd: 1,
      payerInfo: {
        payer: {
          billingDifferent: false,
        },
      },
      clientFirstName: '',
      clientLastName: '',
      approvedApply: false,
      showAlertModal: false,
      closeAlertModal: true,
      textAlertModal: '',
      accountNumber: null,
    }
    this.dBuyTimeout = null
  }

  UNSAFE_componentWillReceiveProps({ financePlans, currentPlan, isScriptLoaded, isScriptLoadSucceed }) {
    const { isScriptLoaded: isScriptLoadedProps } = this.props
    const { financeCode, currentTotal } = this.state
    const order = getOrder()

    if (isScriptLoaded && !isScriptLoadedProps) {
      if (isScriptLoadSucceed) {
        this.setState({ unavailableButton: false })
        clearTimeout(this.dBuyTimeout)
      } else {
        console.error('Cannot load Digital Buy script!')
      }
    }

    if (currentTotal === 0) {
      this.updateTotal()
    }

    if (isScriptLoadSucceed) {
      this.setState({ enableButton: currentPlan && financeCode.length >= 3 })
    }

    this.setDefaultPlan(financePlans, currentPlan)
  }

  componentDidUpdate(prevProps) {
    const order = getOrder()
    if (prevProps?.currentPlan?.financeCode !== order?.financePlan?.code) {
      this.updateTotal()
    }
  }

  closeAlertModal = () => {
    this.setState({ showAlertModal: false })
  }

  closeFinanceModal = () => {
    const { onFinHide, onFinClose } = this.props
    if (onFinClose) onFinClose()
    if (onFinHide) onFinHide(false)
  }

  closeAllModals = () => {
    this.closeAlertModal()
    this.closeFinanceModal()
    scrollTo('payment')
  }

  updateTotal = () => {
    const financePlans = getFinancePlansFromRegion()
    const order = getOrder()
    const currentPlan = financePlans?.filter(plan => plan?.financeCode === order?.financePlan?.code)[0]

    if (currentPlan?.financeCode && currentPlan?.encryptedFinanceCodes) {
      this.setState({
        currentPlan,
        currentTotal: this.calculateTotal(currentPlan, order),
        financeCode: decryptFinancePlans(currentPlan.financeCode, currentPlan.encryptedFinanceCodes),
      })
    }
  }

  calculateTotal = (currentPlan, order) => {
    let total = 0
    if (currentPlan?.downPaymentRequired) {
      if (order?.paymentInfo?.length && order?.amountDue > 0) {
        total = order.amountDue - order.tax - order.totalDeliveryCharge
      } else {
        total = order.subtotal
      }
    } else if (order?.paymentInfo?.length && order?.amountDue > 0) {
      total = order.amountDue
    } else {
      total = order.total
    }
    return this.currencyFormat(total, true)
  }

  setDefaultPlan = (financePlans, currentPlan) => {
    if (currentPlan?.name) {
      if (document.getElementById(currentPlan.name)?.checked === false) {
        document.getElementById(currentPlan.name).click()
      }
    } else if (financePlans?.length) {
      if (document.getElementById(financePlans[0].name)) {
        document.getElementById(financePlans[0].name).click()
      }
    }
  }

  currencyFormat = (price, forceCents = false) => {
    let priceNumber = Number(price)
    if (forceCents || !Number.isInteger(priceNumber)) {
      priceNumber = priceNumber.toFixed(2)
    } else {
      priceNumber = priceNumber.toFixed(0)
    }
    return priceNumber
  }

  formatNumber = string => Number(string.replace(',', ''))

  sendAlert = msg => {
    this.setState({
      showAlertModal: true,
      closeAlertModal: true,
      textAlertModal: msg || "We're unable to process this payment, please try again.",
    })
    this.setState({ clicked: false })
  }

  resetClientToken = msg => {
    if (msg !== false) {
      this.sendAlert(msg)
    }
    this.setState({ clientToken: null, processInd: 1 })
    if (document.getElementById('dbuymodal2')) {
      document.getElementById('dbuymodal2').remove()
    }
    this.closeFinanceModal()
    return false
  }

  openModal = async formId => {
    const { clientToken, processInd } = this.state
    if (processInd === 1) {
      if (document.getElementById('dbuymodal2')) {
        document.getElementById('dbuymodal2').remove()
      }
    }
    if (!clientToken) {
      await fetchClientToken().then(data => this.setState({ clientToken: data.clientToken }))
    }
    this.setState({ clicked: true })
    const form = document.getElementById(formId)
    // eslint-disable-next-line no-unused-expressions
    window.syfDBuy && window.syfDBuy.calldBuyProcess(form)
    addToDataLayer('click', 'checkout', 'synchrony digital buy')
    this.closeModal()
  }

  messageEvent = event => {
    const updateClickedState = () => this.setState({ clicked: false })
    const checkTokenStatus = () => this.checkTokenStatusMethod()
    const { processInd } = this.state
    if (
      typeof event.data === 'string' &&
      (event.data === 'Close Model' || event.data === 'Return To Merchant Shipping')
    ) {
      updateClickedState()
      checkTokenStatus()
    }
  }

  closeModal = () => {
    window.addEventListener('message', this.messageEvent)
  }

  updateModalInfo = openToBuyAmount => {
    if (openToBuyAmount) {
      this.setState({
        currentTotal: this.currencyFormat(openToBuyAmount, true),
        processInd: 2,
      })
    } else {
      this.setState({ processInd: 2 })
    }
    document.getElementById('digBuyButton').click()
  }

  updatePayerInfo = clientTokenSatus => {
    if (clientTokenSatus.FirstName && clientTokenSatus.LastName) {
      this.setState({ clientFirstName: clientTokenSatus.FirstName, clientLastName: clientTokenSatus.LastName })
    }
    if (clientTokenSatus.AccountNumber) {
      this.setState({ accountNumber: clientTokenSatus.AccountNumber })
    }
  }

  updateOrderInfo = async (order, clientFirstName, clientLastName, clientTokenSatus) => {
    await this.setState({
      payerInfo: {
        payer: {
          ...order.payer,
          firstName: clientTokenSatus.FirstName || clientFirstName,
          lastName: clientTokenSatus.LastName || clientLastName,
          email: order.contact.email,
          phone: order.contact.phone,
          billingDifferent: true,
          billingSubmitted: true,
        },
        billingAddress: {
          ...order.billingAddress,
          ...this.updateBillingAddress(clientTokenSatus),
        },
      },
    })
  }

  paymentSessionTimeout = () => {
    this.dBuyTimeout = setTimeout(() => {
      const order = getOrder()
      const dBuyPayment = order.paymentInfo.filter(payment => payment.paymentType === 'DBUY')
      if (order.orderId && dBuyPayment && window.location.pathname === '/checkout') {
        this.resetPaymentMethods()
      }
    }, 900000) /* Token will timeout after 15 mins */
  }

  processPaymentModal = () => {
    this.setState({
      showAlertModal: true,
      closeAlertModal: false,
      textAlertModal: 'Please wait while we process your payment...',
    })
  }

  checkTokenStatusMethod = () => {
    const order = getOrder()
    const { clientToken, processInd, currentTotal, approvedApply, clientFirstName, clientLastName } = this.state

    fetchClientTokenSatus({ userToken: clientToken })
      .then(async clientTokenSatus => {
        if (clientTokenSatus.StatusCode === '000') {
          // Authorization Approved / Purchase Success
          this.processPaymentModal()
          await this.updateAddressMethod(clientTokenSatus)
          this.paymentSessionTimeout(order)
        } else if (clientTokenSatus.StatusCode === '033') {
          // Customer Approval Success
          this.processPaymentModal()
          await this.updateAddressMethod(clientTokenSatus)
          this.paymentSessionTimeout(order)
        } else if (clientTokenSatus.StatusCode === '100' || clientTokenSatus.StatusCode === '001') {
          // Customer Terminated (100) or Authorization Declined (001)
          if (clientTokenSatus.OpenToBuyAmount) {
            // Check for openToBuyAmount credit
            const openToBuyAmount = this.formatNumber(clientTokenSatus.OpenToBuyAmount)
            if (openToBuyAmount > 0 && openToBuyAmount < currentTotal) {
              this.updateModalInfo(openToBuyAmount)
            } else if (openToBuyAmount <= 0) {
              // cancel / denied credit limit increase request
              this.resetClientToken('You do not have enough available credit limit, please try another payment.')
            } else if (clientTokenSatus.StatusCode === '100') {
              this.resetClientToken(false)
            } else {
              this.updateModalInfo()
            }
          } else if (processInd === 2) {
            this.resetClientToken()
          } else {
            this.resetClientToken(false)
          }
        } else if (clientTokenSatus.StatusCode === '002' || clientTokenSatus.StatusCode === '003') {
          // Account Authentication Success (002, 003)
          this.updatePayerInfo(clientTokenSatus)
          if (clientTokenSatus.Address1) {
            // CCV verification passed
            this.updateOrderInfo(order, clientFirstName, clientLastName, clientTokenSatus)
            if (clientTokenSatus.OpenToBuyAmount) {
              // Check for openToBuyAmount credit
              const openToBuyAmount = this.formatNumber(clientTokenSatus.OpenToBuyAmount)
              if (openToBuyAmount > 0 && openToBuyAmount < currentTotal) {
                this.updateModalInfo(openToBuyAmount)
              } else if (openToBuyAmount <= 0) {
                // request credit limit increase
                this.updateModalInfo()
              } else {
                this.updateModalInfo()
              }
            } else {
              this.updateModalInfo()
            }
          } else {
            this.resetClientToken('Please verify the card CCV code and try again.')
          }
        } else if (clientTokenSatus.StatusCode === '401') {
          // Transaction Failed (Token Expired Scenario)
          this.resetClientToken('Your payment session has expired, please try again.')
        } else if (clientTokenSatus.StatusCode === '403') {
          // Address verification check Fail
          if (clientTokenSatus.Address1) {
            this.updateOrderInfo(order, clientFirstName, clientLastName, clientTokenSatus)
            if (approvedApply) {
              this.setState({ approvedApply: false })
              this.updateModalInfo()
            } else {
              this.resetClientToken('Please add or verify the card billing address and try again.')
            }
          } else {
            this.resetClientToken('Please add or verify the card billing address and try again.')
          }
        } else if (clientTokenSatus.StatusCode === '00') {
          // eApply_Transaction Approved
          this.updatePayerInfo(clientTokenSatus)
          if (clientTokenSatus.Address1) {
            this.updateOrderInfo(order, clientFirstName, clientLastName, clientTokenSatus)
          }
          this.setState({ approvedApply: true })
          this.updateModalInfo()
        } else if (clientTokenSatus.StatusCode === '07') {
          // eApply_Transaction Declined
          this.resetClientToken(`Your application has been declined, please try another payment.`)
        } else if (clientTokenSatus.responseCode === '503') {
          // SERVICE UNAVAILABLE
          this.resetClientToken()
        } else {
          this.resetClientToken(false)
        }
      })
      .catch(error => {})
    this.setState({ clicked: false })
    window.removeEventListener('message', this.messageEvent)
  }

  updateBillingAddress = clientTokenSatus => {
    if (!clientTokenSatus.Address1) {
      return {}
    }
    return {
      address1: clientTokenSatus.Address1,
      address2:
        clientTokenSatus.Address2 &&
        clientTokenSatus.Address2 !== '' &&
        clientTokenSatus.Address2 !== 'null' &&
        clientTokenSatus.Address2 !== 'undefined'
          ? clientTokenSatus.Address2
          : '',
      city: clientTokenSatus.City,
      state: clientTokenSatus.State,
      zip: clientTokenSatus.ZipCode?.slice(0, 5) || clientTokenSatus.Zipcode?.slice(0, 5),
    }
  }

  resetPaymentMethods = () => {
    const order = getOrder()

    updatePayment({
      orderId: order.orderId,
      paymentInfo: [],
    })
      .then(data => {
        data.selectedPaymentType = 'Rooms To Go'
        store.dispatch(setOrder(data))
        store.dispatch(setCheckoutStep('payment'))
        this.sendAlert('Your payment session has expired, please try again.')
        window.location.reload()
      })
      .catch(error => {
        this.sendAlert()
      })
  }

  updatePaymentMethod = clientTokenSatus => {
    const order = getOrder()
    const { currentPlan, financeCode, currentTotal, payerInfo, accountNumber } = this.state

    let dbPaymentInfo = []
    const giftCards = order.paymentInfo.filter(payment => payment.paymentType === 'GIFT')
    if (giftCards.length > 0) {
      dbPaymentInfo = giftCards
    }
    dbPaymentInfo.push({
      authorized: false,
      paymentType: 'DBUY',
      paymentProperties: {
        userToken: clientTokenSatus.TokenId,
        financePlan: order.financePlan.code,
        hasPayments: currentPlan.downPaymentRequired,
        accountNumber: clientTokenSatus.accountNumber || accountNumber,
        promoCode: financeCode,
        billingAddress: payerInfo.billingAddress,
      },
      authorizedAmount: this.formatNumber(currentTotal),
    })

    updatePayment({
      paymentInfo: dbPaymentInfo,
      orderId: order.orderId,
    })
      .then(paidOrder => {
        if (paidOrder.amountDue <= 0) {
          this.closeAlertModal()
          store.dispatch(setOrder({ ...paidOrder, isNonFianceCredit: true }))
          store.dispatch(setCheckoutStep('review'))
          checkoutStepAnalytics('review')
        } else {
          store.dispatch(
            setOrder({
              ...paidOrder,
              selectedPaymentType: 'Credit',
              isNonFianceCredit: true,
            }),
          )
        }
        this.closeFinanceModal()
        scrollTo('payment')
      })
      .catch(error => {
        this.closeAllModals()
        this.sendAlert()
      })
  }

  updateAddressMethod = clientTokenSatus => {
    const order = getOrder()

    order.selectedPaymentType = 'Rooms To Go'

    order.payer = {
      ...order.payer,
      firstName: order.contact.firstName,
      lastName: order.contact.lastName,
      email: order.contact.email,
      phone: order.contact.phone,
    }

    order.billingAddress = {
      ...order.shippingAddress,
    }

    updateAddress(getAddressSpecificBody(order, true))
      .then(async addrOrder => {
        store.dispatch(setOrder(addrOrder))
        await this.updatePaymentMethod(clientTokenSatus)
      })
      .catch(error => {
        this.closeAllModals()
      })
  }

  render() {
    const { order, onFinHide } = this.props
    const {
      clicked,
      unavailableButton,
      enableButton,
      clientToken,
      financeCode,
      currentTotal,
      processInd,
      payerInfo,
      showAlertModal,
      closeAlertModal,
      textAlertModal,
    } = this.state

    if (unavailableButton || currentTotal === 0) {
      return (
        <DigitalBuyWrapper>
          <SubmitRtgButton
            type="button"
            data-testid="digital-buy-button-disabled"
            aria-label="Synchrony Digital Buy is currently unavailable."
            value="Currently Unavailable"
            onClick={() => {}}
            disabled
          >
            Currently Unavailable
          </SubmitRtgButton>
        </DigitalBuyWrapper>
      )
    }

    return (
      <>
        <DigitalBuyWrapper>
          {order.contact && order.shippingAddress && (
            <div className={classNames('digital-buy-link', { hide: false })}>
              <form name="dbuyform3" id="dbuyform3">
                <input type="hidden" name="processInd" value={processInd} />
                <input type="hidden" name="iniPurAmt" value={currentTotal} />
                <input type="hidden" name="tokenId" value={clientToken} />
                <input type="hidden" name="merchantID" value={process.env.GATSBY_CREDIT_MID} />
                <input type="hidden" name="clientTransId" value={order.orderId} />

                <input type="hidden" name="phoneNumber" value={order.contact.phone} />
                <input type="hidden" name="emailAddress" value={order.contact.email} />

                <input
                  type="hidden"
                  name="custFirstName"
                  value={payerInfo.payer.billingDifferent ? payerInfo.payer.firstName : order.contact.firstName}
                />
                <input
                  type="hidden"
                  name="custLastName"
                  value={payerInfo.payer.billingDifferent ? payerInfo.payer.lastName : order.contact.lastName}
                />
                <input
                  type="hidden"
                  name="custZipCode"
                  value={payerInfo.payer.billingDifferent ? payerInfo.billingAddress.zip : order.shippingAddress.zip}
                />
                <input
                  type="hidden"
                  name="custAddress1"
                  value={
                    payerInfo.payer.billingDifferent
                      ? payerInfo.billingAddress.address1
                      : order.shippingAddress.address1
                  }
                />
                <input
                  type="hidden"
                  name="custAddress2"
                  value={
                    payerInfo.payer.billingDifferent
                      ? payerInfo.billingAddress.address2
                      : order.shippingAddress.address2
                  }
                />
                <input
                  type="hidden"
                  name="custCity"
                  value={payerInfo.payer.billingDifferent ? payerInfo.billingAddress.city : order.shippingAddress.city}
                />
                <input
                  type="hidden"
                  name="custState"
                  value={
                    payerInfo.payer.billingDifferent ? payerInfo.billingAddress.state : order.shippingAddress.state
                  }
                />

                <input type="hidden" name="transPromo1" value={financeCode} />
                <input type="hidden" name="transAmount1" value={currentTotal} />
                <input type="hidden" name="defaultPromoCode" value={financeCode} />

                <SubmitRtgButton
                  id="digBuyButton"
                  type="button"
                  data-toggle="modal"
                  data-target="#digBuyModal"
                  data-testid="digital-buy-button"
                  aria-label="Continue with online application (opens in new window)"
                  value="Submit"
                  disabled={!enableButton}
                  style={{ cursor: !enableButton ? 'default' : 'pointer' }}
                  onClick={async () => {
                    if (enableButton) {
                      await this.openModal('dbuyform3')
                      if (onFinHide) onFinHide(true)
                    }
                  }}
                >
                  {!clicked && 'Submit'}
                  {clicked && <LoadingSpinner alt="Submitting rooms to go credit card" src={loaderLight} />}
                </SubmitRtgButton>
                {/* <br />
                <br />
                <input
                  type="number"
                  name="changeFinanceCode"
                  value={financeCode}
                  placeholder="Promotion to Use"
                  min="3"
                  onChange={e => this.setState({ financeCode: e.target.value })}
                /> */}
              </form>
            </div>
          )}
        </DigitalBuyWrapper>
        {showAlertModal && (
          <AlertModal
            label="Digital Buy Modal"
            btnClass="dbuy-alert-close-btn"
            mdlClass="dbuy-alert-modal"
            shouldShowModal={showAlertModal}
            closeModal={closeAlertModal ? this.closeAlertModal : false}
          >
            <div>{textAlertModal}</div>
          </AlertModal>
        )}
      </>
    )
  }
}

DigitalBuy.propTypes = {
  order: objectOf(any).isRequired,
  isScriptLoaded: bool,
  isScriptLoadSucceed: bool,
  currentPlan: object,
  financePlans: object,
  onFinHide: func,
  onFinClose: func,
}

export default scriptLoader([process.env.GATSBY_SYNCHRONY_DIGITAL_BUY_URL])(DigitalBuy)
