import React from 'react'
import { objectOf, any, string, arrayOf, array, object, bool } from 'prop-types'
import { connect } from 'react-redux'
import { getDeliverySectionData } from '@helpers/checkout/delivery-section'
import { updateAddress } from '@services/checkout'
import { getAddressSpecificBody, alertAndReturnToCart } from '@helpers/checkout/shipping-section'
import ErrorMessage from '@shared/error-message'
import DeliveryCondensed from './delivery-condensed'
import VendorSection from './vendor-section'
import DeliverySectionForm from './delivery-section-form'
import DeliverySectionAutomatedMessage from './delivery-section-automated-message'
import { DeliverySectionWrapper } from './styles'

const DeliverySection = ({
  order,
  checkoutStep,
  checkoutStepsCompleted,
  invalidFields,
  cart,
  deliveryCalendar,
  storeCartLineItems,
  deliveryMode,
}) => {
  const deliverySectionData = getDeliverySectionData(order, deliveryCalendar)
  const {
    rtgDeliveryItems,
    vendorDeliveryItems,
    upsDeliveryItems,
    uspsDeliveryItems,
    isExpress,
    fullDeliveryDate,
  } = deliverySectionData
  const deliveryCondensed = checkoutStep !== 'delivery' && checkoutStepsCompleted.delivery

  const checkDeliveryDate = () => {
    if (fullDeliveryDate) return fullDeliveryDate
    if (order && order.deliveryCalendar) return order.deliveryCalendar[0]
    if (!fullDeliveryDate && !order.deliveryCalendar)
      return updateAddress(getAddressSpecificBody(order)).catch(() => {})
    return null
  }

  return (
    <>
      {deliveryCondensed && order && (
        <DeliveryCondensed
          deliveryDate={checkDeliveryDate()}
          order={order}
          deliveryMode={deliveryMode}
          rtgDeliveryItems={rtgDeliveryItems}
          vendorDeliveryItems={vendorDeliveryItems}
          upsDeliveryItems={upsDeliveryItems}
          uspsDeliveryItems={uspsDeliveryItems}
          totalDeliveryCharge={order.totalDeliveryCharge}
          isExpress={isExpress}
          cart={cart}
          storeCartLineItems={storeCartLineItems}
        />
      )}
      {checkoutStep === 'delivery' && order && (
        <DeliverySectionWrapper>
          {invalidFields.length > 0 && (
            <ErrorMessage
              invalidFields={invalidFields}
              customMessage={
                invalidFields.includes('payment incomplete')
                  ? 'You must complete delivery and payment before proceeding to review order.'
                  : 'There was an issue proceeding through checkout. Please refresh and try again.'
              }
            />
          )}
          <DeliverySectionForm
            order={order}
            deliveryCalendar={deliveryCalendar}
            rtgDeliveryItems={rtgDeliveryItems}
            fullDeliveryDate={checkDeliveryDate()}
            isExpress={isExpress}
            cart={cart}
            deliveryCondensed={deliveryCondensed}
            checkoutStep={checkoutStep}
            storeCartLineItems={storeCartLineItems}
          />
          {vendorDeliveryItems.length > 0 && <VendorSection deliveryItems={vendorDeliveryItems} cart={cart} />}
          {upsDeliveryItems.length > 0 && <VendorSection deliveryItems={upsDeliveryItems} cart={cart} ups />}
          {uspsDeliveryItems.length > 0 && <VendorSection deliveryItems={uspsDeliveryItems} cart={cart} usps />}
        </DeliverySectionWrapper>
      )}
      <DeliverySectionAutomatedMessage
        order={order}
        deliveryCondensed={deliveryCondensed}
        rtgDeliveryItems={rtgDeliveryItems}
        isExpress={isExpress}
      />
    </>
  )
}

DeliverySection.propTypes = {
  order: objectOf(any),
  checkoutStep: string,
  checkoutStepsCompleted: objectOf(bool),
  invalidFields: arrayOf(string),
  cart: objectOf(any),
  deliveryCalendar: arrayOf(object),
  storeCartLineItems: array,
  deliveryMode: string,
}

const mapStateToProps = state => ({ deliveryMode: state.checkout.deliveryMode })

export default connect(mapStateToProps, null)(DeliverySection)
