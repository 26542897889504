import React, { useState } from 'react'
import DeliveryDateModal from '@shared/modals/DatePicker'
import { months, weekdays } from '@helpers/string-helper'
import { ClickAwayListener } from '@mui/base'
import { updateDelivery } from '@services/checkout'
import { getDeliverySpecificBody } from '@helpers/checkout/delivery-section'
import { setOrder } from '@redux/modules/checkout'
import { updateDeliveryAnalytics } from '@helpers/google-tag-manager'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { breakPoints, colors } from '@constants/styles'
import { any, bool, objectOf, string } from 'prop-types'

const StyledButton = styled.button`
  color: ${colors.primary};
  margin-top: ${({ showAsLink }) => (showAsLink ? '' : '3px  !important')};
  font-size: ${({ bold }) => (bold ? '18px  !important' : '15px')};
  font-weight: ${({ bold }) =>
    bold ? '700 !important' : ({ showAsLink }) => (showAsLink ? 'normal !important' : '700 !important')};
  font-style: ${({ bold }) =>
    bold ? 'normal  !important' : ({ showAsLink }) => (showAsLink ? 'italic' : 'normal  !important')};
  text-transform: ${({ showAsLink }) => (showAsLink ? 'capitalize  !important' : 'uppercase  !important ')};
  text-decoration: ${({ showAsLink }) =>
    showAsLink ? 'underline dotted  !important' : 'currentColor solid none  !important'};
  text-underline-offset: ${({ showAsLink }) => (showAsLink ? '2px  !important' : '0  !important')};
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    font-size: ${({ bold }) =>
      bold ? '14px !important' : ({ showAsLink }) => (showAsLink ? '12px !important' : '15px !important')};
  }
`

export const CalendarPopupSplit = ({
  order,
  myRef,
  initialDate,
  shipment,
  shipmentsData,
  index,
  showAsLink = false,
  bold = false,
  text = 'Change Date',
  testId,
}) => {
  const dispatch = useDispatch()
  const [shipmentModalVisibility, setShipmentModalVisibility] = useState(null)

  const updateShipmentDeliveryDate = (date, indx) => {
    if (order) {
      const splitDeliveryDates = order.splitDeliveryDates.map(item => item.deliveryDate)
      splitDeliveryDates[indx] = date.date

      const updateOrder = {
        ...order,
        splitDelivery: splitDeliveryDates,
      }
      return updateDelivery(getDeliverySpecificBody(updateOrder, updateOrder?.deliveryMode)).then(data => {
        dispatch(setOrder(data))
        setShipmentModalVisibility(null)
        updateDeliveryAnalytics(data)
      })
    }
    return null
  }

  const getDeliveryDates = (isPickup, indx) => {
    const dateFormatter = dateString => {
      const newDate = new Date(dateString)
      return {
        date: dateString,
        readable: `${months[newDate.getUTCMonth()]} ${newDate.getUTCDate()}`,
        dayOfWeek: weekdays[newDate.getUTCDay()].substring(0, 3),
      }
    }

    const { deliveryCalendar } = shipmentsData?.[indx]?.data
    if (!isPickup) {
      /* premium delivery or doorway delivery dates with potential express dates prepended to the front of a new array */
      if (deliveryCalendar?.expressDeliveryDates?.length > 0) {
        return [
          ...deliveryCalendar?.expressDeliveryDates
            ?.map(dateFormatter)
            ?.map(date => ({ ...date, isExpressDelivery: true })),
          ...deliveryCalendar?.deliveryDates?.map(dateFormatter),
        ]
      }
      return deliveryCalendar?.deliveryDates?.map(dateFormatter)
    }

    /* pickup delivery dates */
    return deliveryCalendar?.pickupDates?.map(dateFormatter)
  }

  return (
    <ClickAwayListener onClickAway={() => setShipmentModalVisibility(null)}>
      {/* set a reference to the parent div so we can place the popup in the correct position */}
      <>
        <StyledButton
          data-testid={testId}
          onClick={() => setShipmentModalVisibility(index)}
          onKeyDown={e => e.keyCode === 13 && setShipmentModalVisibility(index)}
          showAsLink={showAsLink}
          bold={bold}
          type="button"
          value="Select Delivery Date"
        >
          {text}
        </StyledButton>
        <DeliveryDateModal
          initialDate={{
            date: initialDate,
            readable: `${
              months[shipment.deliveryDateObject.getUTCMonth()]
            } ${shipment.deliveryDateObject.getUTCDate()}`,
          }}
          isOpen={shipmentModalVisibility === index}
          closeModal={() => setShipmentModalVisibility(null)}
          onSubmit={date => updateShipmentDeliveryDate(date, index)}
          dateRange={getDeliveryDates(order.isPickup, index)}
          contentLabel="Change Delivery Date Calendar"
          buttonText="Change Delivery Date"
          currentRef={myRef} // pass in the div ref
        />
      </>
    </ClickAwayListener>
  )
}

CalendarPopupSplit.propTypes = {
  order: objectOf(any),
  myRef: objectOf(any),
  initialDate: string,
  showAsLink: bool,
  shipment: any,
  index: any,
  bold: bool,
  text: string,
  shipmentsData: any,
  testId: string,
}

export default CalendarPopupSplit
