import { fetchProductBySku, fetchProductWarehouseAvailability } from '@services/product'
import { store } from '@redux/store'
import { setOrder, setCheckoutStep } from '@redux/modules/checkout'
import {
  addProductToCart,
  updateCartProductQuantity,
  removeProductFromCart,
  setCart,
  addActiveAddon,
  removeActiveAddon as removeActiveAddonRedux,
  setWarrantyEnabled as setWarrantyEnabledRedux,
  setAddOnWarrantyEnabled as setAddOnWarrantyEnabledRedux,
  setStoreCartCreationData,
} from '@redux/modules/cart'
import { createOrder, getOrder as fetchOrder, updateLineItems } from '@services/checkout'
import { getSavedCouponObj, getSavedCouponType, hasSavedCoupon } from '@services/coupon'
import {
  productPrice,
  productOnSale,
  availabilityStockMessage,
  fetchProductOrGC,
  getWarrantyDataByRegionZone,
} from './product'
// eslint-disable-next-line import/no-cycle
import { analyticsProduct, addToDataLayer } from './google-tag-manager'
import { saveLocalStorage, getFromBrowserStorage } from './storage'
import { taskDone, announce } from './aria-announce'
// eslint-disable-next-line import/no-cycle
import { clearCheckoutState, getOrder, getLineItems } from './checkout/global'
import { getRegionZone, getCurrentLocation } from './geo-location'

export const getCart = () => store.getState().cart.cart

export const filterAddonPricing = addon_items => {
  if (!addon_items) return
  const { region, price_zone } = getCurrentLocation()
  addon_items.forEach(addonItem => {
    if (addonItem.pricing) {
      Object.keys(addonItem.pricing).forEach(regionalPricing => {
        if (!regionalPricing.startsWith(`${region}_${price_zone}`) && !regionalPricing.startsWith('default')) {
          delete addonItem.pricing[regionalPricing]
        }
      })
    }
  })
}

export const cartProduct = (product, quantity) => {
  if (product) {
    const { region } = getCurrentLocation()
    let items_in_room

    filterAddonPricing(product.addon_items)

    if (product.items_in_room && Array.isArray(product.items_in_room)) {
      items_in_room = product.items_in_room
    } else if (
      product.items_in_room &&
      (product.items_in_room.FL || product.items_in_room.SE || product.items_in_room.TX)
    ) {
      Object.entries(product.items_in_room).forEach(([key, value]) => {
        if (key.includes(region)) {
          items_in_room = value
        }
      })
    }
    return {
      ...product,
      price: productPrice(product),
      list_price: productPrice(product, true),
      quantity: product.quantity || quantity,
      on_sale: productOnSale(product),
      items_in_room,
    }
  }
  return null
}

export const fetchAndAddActiveAddon = (product, cartIndex, addon) => {
  fetchProductBySku(addon.sku).then(fetchedAddon =>
    store.dispatch(
      addActiveAddon(
        product.sku,
        {
          ...fetchedAddon,
          quantity: addon.quantity,
        },
        cartIndex,
      ),
    ),
  )
}

export const removeActiveAddon = (product, addonSku, cartIndex) => {
  store.dispatch(removeActiveAddonRedux(product.sku, addonSku, cartIndex))
}

export const setWarrantyEnabled = (sku, warrantyEnabled, cartIndex) => {
  store.dispatch(setWarrantyEnabledRedux(sku, warrantyEnabled, cartIndex))
}

export const setAddOnWarrantyEnabled = (sku, warrantyEnabled, cartIndex) => {
  store.dispatch(setAddOnWarrantyEnabledRedux(sku, warrantyEnabled, cartIndex))
}

export const getLineItemQuantity = (order = null) => {
  let count = 0
  let count2 = 0

  // get seperate carts
  const cart = getCart()
  let storeCartLineItems = []

  if (order && order.lineItems) {
    storeCartLineItems = order.lineItems.filter(lineItem => lineItem.isStoreSku)
  }

  // combine carts
  const combinedCart = [...(cart.cartItems || []), ...(storeCartLineItems || [])]
  const cartHasItems = combinedCart && combinedCart.length > 0
  if (cartHasItems) {
    combinedCart.forEach(item => {
      const { activeAddons, product, quantity } = item
      count += parseInt(quantity)
      if (activeAddons) {
        // eslint-disable-next-line no-return-assign
        activeAddons.map(addon => (count += parseInt(quantity * addon.quantity)))
      }
      if (item && product && product.addon_items && activeAddons) {
        const { addon_items } = product
        const requiredAddons = addon_items.filter(addon => addon.addon_required)
        const productHasAddOns = requiredAddons && requiredAddons.length > 0
        if (productHasAddOns) {
          requiredAddons.map(rqdAddOn => {
            const addOnsLength = activeAddons.filter(addon => addon.sku === rqdAddOn.sku).length
            if (!activeAddons || (activeAddons && addOnsLength < 1)) {
              count2 += quantity * rqdAddOn.quantity
            }
            return count2
          })
        }
      }
    })
  }

  return {
    cart: count,
    total: count + count2,
  }
}

export const getCartItemQuantity = sku => {
  const cart = getCart()
  if (cart && cart.cartItems && cart.cartItems.length > 0) {
    for (let i = 0, n = cart.cartItems.length; i < n; i++) {
      if (sku === cart.cartItems[i].sku) {
        return cart.cartItems[i].quantity
      }
    }
  }
  return 0
}

export function addToCart(product, price, activeAddons, warrantyEnabled, quantity = 1) {
  clearCheckoutState(false, true)
  const cart = getCart()
  const cartItems = cart?.cartItems ? cart.cartItems : []
  let alreadyInCart = false
  let existingProduct

  cartItems.forEach(cartItem => {
    if (
      cartItem.sku === product.sku &&
      (JSON.stringify(activeAddons) === JSON.stringify(cartItem.activeAddons) ||
        (!activeAddons && !cartItem.activeAddons))
    ) {
      alreadyInCart = true
      existingProduct = cartItem
    }
  })

  if (!alreadyInCart) {
    store.dispatch(
      addProductToCart({
        sku: product.sku,
        quantity,
        product: cartProduct(product),
        price,
        activeAddons,
        warrantyEnabled,
      }),
    )
  } else {
    const productQuantity = parseInt(existingProduct.quantity) + quantity
    store.dispatch(updateCartProductQuantity(existingProduct.sku, productQuantity > 10 ? 10 : productQuantity))
  }
}

export function removeFromCart(product, index) {
  clearCheckoutState(false, true)
  const cart = getCart()
  if (cart && cart.cartItems) {
    const { cartItems } = cart

    cartItems.forEach((cartItem, i) => {
      if (product.sku === cartItem.sku && index === i) {
        const { quantity } = cartItem
        fetchProductOrGC(product.sku).then(data => {
          window.dataLayer.push({
            event: 'ee_remove',
            ecommerce: {
              remove: {
                products: [analyticsProduct(data, quantity)],
              },
            },
          })
        })
        store.dispatch(removeProductFromCart(product.sku))
      }
    })

    const cartTotal = getCartTotal(cart)
    announce(`Item removed. New cart total: $${cartTotal}`)
  }
  if (cart?.cartItems?.length === 0) {
    try {
      taskDone(
        () => {
          const ele = document.getElementById('empty-cart-continue')
          if (ele) {
            ele.focus()
          }
        },
        500,
        'focusEmpty',
      )
    } catch (e) {}
  }
}

export function getCartTotal(cart) {
  let subtotal = 0
  if (cart && cart.cartItems) {
    const { cartItems } = cart
    for (let i = 0; i < cartItems.length; i++) {
      subtotal += cartItems[i].quantity * productPrice(cartItems[i].product)
      if (cartItems[i].activeAddons) {
        for (let x = 0, n = cartItems[i].activeAddons.length; x < n; x++) {
          subtotal +=
            productPrice(cartItems[i].activeAddons[x]) * cartItems[i].activeAddons[x].quantity * cartItems[i].quantity
        }
        if (cartItems[i].activeAddons[i] && cartItems[i].activeAddons[i].warrantyEnabled) {
          const warrantyData = getWarrantyDataByRegionZone(
            cartItems[i].activeAddons[i].warranty_pricing,
            getRegionZone(),
          )
          subtotal = warrantyData ? subtotal + cartItems[i].quantity * warrantyData.price : subtotal
        }
      }
      if (cartItems[i].warrantyEnabled) {
        const warrantyPricingAllRegionZones = cartItems[i].product && cartItems[i].product.warranty_pricing
        const warrantyData = warrantyPricingAllRegionZones
          ? getWarrantyDataByRegionZone(warrantyPricingAllRegionZones, getRegionZone())
          : cartItems[i].warrantyPrice
        subtotal = warrantyData ? subtotal + cartItems[i].quantity * warrantyData.price : subtotal
      }
    }
  }
  return subtotal
}

export function getStoreCartTotal(storeCart) {
  let subtotal = 0
  if (storeCart) {
    storeCart.forEach((item, i) => {
      const { quantity, unitPrice } = item
      subtotal += quantity * unitPrice
      if (item.activeAddons) {
        for (let x = 0, n = item.activeAddons.length; x < n; x++) {
          subtotal += productPrice(item.activeAddons[x]) * item.activeAddons[x].quantity * item.quantity
        }
        if (item.activeAddons[i] && item.activeAddons[i].warrantyEnabled) {
          const warrantyData = getWarrantyDataByRegionZone(item.activeAddons[i].warranty_pricing, getRegionZone())
          subtotal = warrantyData ? subtotal + item.quantity * warrantyData.price : subtotal
        }
      }
      if (item.warrantyEnabled) {
        const warrantyPricingAllRegionZones = item.product && item.product.warranty_pricing
        const warrantyData = warrantyPricingAllRegionZones
          ? getWarrantyDataByRegionZone(warrantyPricingAllRegionZones, getRegionZone())
          : item.warrantyPrice
        subtotal = warrantyData ? subtotal + item.quantity * warrantyData.price : subtotal
      }
    })
  }

  return subtotal
}

export const verifyAndUpdateCart = async cartIn => {
  const cart = {
    ...cartIn,
  }
  if (cart && cart.cartItems && cart.cartItems.length > 0) {
    const { cartItems } = cart
    const fetchPromises = cartItems.reduce((promises, cartItem) => {
      promises.push(fetchProductOrGC(cartItem.sku))
      return promises
    }, [])
    const products = await Promise.all(fetchPromises)
    for (let i = 0, n = cartItems.length; i < n; i++) {
      if (cartItems[i]) {
        const product = products[i]
        cartItems[i].product = cartProduct(product)
        cartItems[i].price = productPrice(product)
        if (i === n - 1) {
          cart.cartItems = cartItems
        }
      }
    }
  }
  saveLocalStorage('cart', cart)
}

export const checkProductRegionAvailability = async regionIn => {
  const rtg_location = getCurrentLocation()
  const region = regionIn || rtg_location?.region || 'FL'
  let cart = getCart()

  if (!cart || (cart && !cart.cartItems) || (cart && cart.cartItems && cart.cartItems.length < 1)) {
    cart = getFromBrowserStorage('local', 'cart')
  }

  if (cart && cart.cartItems && region) {
    const skus = cart.cartItems.reduce((acc, { product, sku }) => {
      if (
        rtg_location &&
        product &&
        (rtg_location.region !== 'OOM' ||
          ((product.delivery_type === 'O' || product.delivery_type === 'U') && rtg_location.region === 'OOM')) &&
        product.category !== 'gift-card'
      ) {
        return acc !== '' ? `${acc},${sku}` : sku
      }

      return acc
    }, '')

    try {
      const stockData = await fetchProductWarehouseAvailability(
        skus,
        rtg_location.distribution_index,
        rtg_location.state,
      )

      const productsbySKU = cart.cartItems.reduce((acc, { product, sku }) => {
        const {
          stock,
          category,
          catalog_availability,
          shipping_cost_code,
          delivery_type,
          customer_assembly_required,
        } = product

        acc[sku] = {
          stock,
          category,
          catalog_availability,
          shipping_cost_code,
          delivery_type,
          customer_assembly_required,
        }
        return acc
      }, {})

      const skusNotAvailable = stockData.data.availabilities
        .map(({ sku, availableOn }) => {
          const product = productsbySKU[sku]
          const stockMessage = availabilityStockMessage({ availableOn, product, rtg_location })
          const outOfStock = ['Discontinued ', 'Available Soon ', 'Out of Stock '].includes(stockMessage)

          if (outOfStock) {
            return {
              sku,
              reason: 'stock',
            }
          }
          if (!product.catalog_availability[region] && !['O', 'U'].includes(product.delivery_type)) {
            return {
              sku,
              reason: 'region',
            }
          }

          return null
        })
        .filter(data => data !== null)

      return skusNotAvailable
    } catch (err) {
      return []
    }
  }

  return []
}

export const getProductsFromCart = cart => {
  const cartObj = cart || getCart()
  const products = []
  const productPromises = []
  if (cartObj && cartObj.cartItems) {
    if (cartObj.cartItems.length > 0) {
      for (let i = 0; i < cartObj.cartItems.length; i++) {
        productPromises.push(fetchProductOrGC(cartObj.cartItems[i].sku))
      }
    } else {
      Object.values(cartObj.cartItems).forEach(value => {
        value.product.quantity = value.quantity
        products.push(value.product)
      })
    }
  }
  return Promise.all(productPromises)
}

export const getRoomsToGoDeliveryItems = cartItems => {
  const deliveryItems = []
  for (let i = 0, n = cartItems.length; i < n; i++) {
    const deliveryTypeBlacklist = ['O', 'U', 'T']
    if (!deliveryTypeBlacklist.includes(cartItems[i].product.delivery_type)) {
      deliveryItems.push(cartItems[i])
    }
  }
  return deliveryItems
}

export const getOtherDeliveryItems = cartItems => {
  const deliveryItems = []
  for (let i = 0, n = cartItems.length; i < n; i++) {
    const deliveryTypeWhitelist = ['O', 'U', 'T']
    if (deliveryTypeWhitelist.includes(cartItems[i].product.delivery_type)) {
      deliveryItems.push(cartItems[i])
    }
  }
  return deliveryItems
}

export const compareLineItems = (cartLineItems, orderLineItems) => {
  for (let i = 0, n = cartLineItems.length; i < n; i++) {
    const orderItems = orderLineItems.filter(orderItem => orderItem.sku === cartLineItems[i].sku)
    let quantity = 0
    for (let x = 0, y = orderItems.length; x < y; x++) {
      quantity += orderItems[x].quantity
    }
    if (quantity !== cartLineItems[i].quantity) {
      return false
    }
  }
  return true
}

export const setNewQuantity = (event, cart, sku, activeAddons) => {
  if (cart && cart.cartItems) {
    const { cartItems } = cart
    for (let i = 0; i < cartItems.length; i++) {
      const quantity = parseInt(event.target.value)
      const productQuantity = cartItems[i].quantity
      if (cartItems[i].sku === sku && JSON.stringify(activeAddons) === JSON.stringify(cartItems[i].activeAddons)) {
        fetchProductOrGC(sku).then(data => {
          if (productQuantity < quantity) {
            window.dataLayer.push({
              event: 'ee_add',
              ecommerce: {
                add: {
                  products: [analyticsProduct(data, quantity - productQuantity)],
                },
              },
            })
          } else if (productQuantity > quantity) {
            window.dataLayer.push({
              event: 'ee_remove',
              ecommerce: {
                remove: {
                  products: [analyticsProduct(data, productQuantity - quantity)],
                },
              },
            })
          }
        })
        store.dispatch(updateCartProductQuantity(cartItems[i].sku, event.target.value))
        addToDataLayer('click', 'cart', 'change quantity', `${sku}, ${event.target.value}`)
      }
    }
    announce(`Quantity Updated. New cart total: $${getCartTotal(cart)}`)
  }
}

export const incrementCartProductQuantity = (newQuantity, cartItems = [], sku, activeAddons) => {
  for (let i = 0; i < cartItems.length; i++) {
    if (cartItems[i].sku === sku && JSON.stringify(activeAddons) === JSON.stringify(cartItems[i].activeAddons)) {
      fetchProductOrGC(sku).then(data => {
        window.dataLayer.push({
          event: 'ee_add',
          ecommerce: {
            add: {
              products: [analyticsProduct(data, newQuantity)],
            },
          },
        })
      })
      store.dispatch(updateCartProductQuantity(cartItems[i].sku, newQuantity))
      addToDataLayer('click', 'cart', 'change quantity', `${sku}, ${newQuantity}`)
    }
  }
  announce(`Quantity Updated.`)
}

export const decrementCartProductQuantity = (newQuantity, cartItems = [], sku, activeAddons) => {
  for (let i = 0; i < cartItems.length; i++) {
    if (cartItems[i].sku === sku && JSON.stringify(activeAddons) === JSON.stringify(cartItems[i].activeAddons)) {
      fetchProductOrGC(sku).then(data => {
        window.dataLayer.push({
          event: 'ee_remove',
          ecommerce: {
            remove: {
              products: [analyticsProduct(data, newQuantity)],
            },
          },
        })
      })
      store.dispatch(updateCartProductQuantity(cartItems[i].sku, newQuantity))
      addToDataLayer('click', 'cart', 'change quantity', `${sku}, ${newQuantity}`)
    }
  }
  announce(`Quantity Updated.`)
}

export const cartUpdate = async ({
  cart,
  storeCartCreationData,
  skusNotAvailableIn,
  apiCalled,
  discount,
  setCartState,
  setApiCalled,
  updateOrder = false,
  setStoreCartIsLoading,
  checkProductAvailability = false,
}) => {
  const { region } = getRegionZone()
  if ((cart && cart.cartItems && region) || storeCartCreationData) {
    // todo refactor cart component, it's temporary workaround
    if (checkProductAvailability) {
      checkProductRegionAvailability().then(skusNotAvailable => {
        if (skusNotAvailableIn.length === 0 || skusNotAvailable.length === 0) {
          setCartState({
            skusNotAvailable,
          })
        }
      })
    }

    const orderStorage = getFromBrowserStorage('session', 'order')
    const lineItems = getLineItems()

    let order = getOrder()
    let verifyQuantities

    // determine if the current cart items & the order Items are different
    if (order && order.lineItems) {
      verifyQuantities = compareLineItems(lineItems, order.lineItems)
    }

    // if there is an order and no local order, store the new order locally
    if (!orderStorage && order) {
      store.dispatch(setOrder(order))
    }

    const location = getCurrentLocation()
    const lineItemsExist = (lineItems && lineItems.length > 0) || storeCartCreationData
    if (
      location &&
      lineItemsExist &&
      (!order.orderId || updateOrder || verifyQuantities === false || storeCartCreationData)
    ) {
      setStoreCartIsLoading(storeCartCreationData, true)
      if (!apiCalled) {
        setApiCalled(true)
        if (order.orderId && storeCartCreationData) {
          order.orderId = null
        }
        if (!order.orderId) {
          // create an order if it doesn't exist
          const storeCartRequestData = storeCartCreationData && {
            storeCartId: storeCartCreationData.id,
            zipcode: storeCartCreationData.deliveryZipCode,
          }

          const orderRequestData = {
            region: location.region,
            zone: parseInt(location.price_zone),
            distribution_index: parseInt(location.distribution_index),
            ...(!storeCartCreationData && { lineItems }),
            ...(storeCartCreationData && storeCartRequestData),
          }

          /* check to see if we need to send a coupon to the backend during cart */
          const hasCoupon = hasSavedCoupon()
          if (hasCoupon) {
            const couponObj = getSavedCouponObj()
            const couponType = getSavedCouponType()
            const customerCareCoupon = ['OFF', 'PER', 'DIS'].some(prefix => prefix === couponType)
            const { code: couponCode, id: couponId } = couponObj
            orderRequestData.thankYouId = customerCareCoupon ? couponCode : couponId
          }

          // create a new order since orderId does not exist
          const newOrder = await createOrder(orderRequestData)
          if (storeCartCreationData) {
            store.dispatch(setStoreCartCreationData(null))
          }

          // update the line items to reflect the new order lineItems
          if (newOrder) {
            const storeCartLineItems = newOrder.lineItems.filter(lineItem => lineItem.isStoreSku)
            order = await updateLineItems({
              orderId: newOrder.orderId,
              lineItems: [...lineItems, ...storeCartLineItems],
              region: location.region,
              zone: parseInt(location.price_zone),
              distribution_index: parseInt(location.distribution_index),
            })
            if (storeCartLineItems.length > 0) {
              store.dispatch(setCheckoutStep('delivery'))
            }
          }
        } else {
          // if the order exists update line items
          const storeCartLineItems = order.lineItems.filter(lineItem => lineItem.isStoreSku)
          order = await updateLineItems({
            orderId: order.orderId,
            lineItems: [...lineItems, ...storeCartLineItems],
            region: location.region,
            zone: parseInt(location.price_zone),
            distribution_index: parseInt(location.distribution_index),
          })
          store.dispatch(setCheckoutStep('shipping'))
        }
        setApiCalled(false)
      }
      if (order) store.dispatch(setOrder(order))
    }
    setStoreCartIsLoading(storeCartCreationData, false)
    setDiscount(order, discount, setCartState)
  }
}

export const setDiscount = (order, discountIn, setCartState) => {
  let discount = 0
  if (order && order.promotions && order.promotions.totalSavings && order.promotions.totalSavings > 0) {
    discount = parseFloat(order.promotions.totalSavings)
  }
  if (discount !== discountIn) {
    setCartState({
      showPayPal: true,
      showApplePay: true,
      discount,
    })
  } else {
    setCartState({
      showPayPal: true,
      showApplePay: true,
    })
  }
}

export const removeUnavailableItems = (setCartState, skusNotAvailableIn) => {
  let cart = getCart()
  if (cart && cart.cartItems) {
    for (let i = cart.cartItems.length - 1; i >= 0; i--) {
      const skusNotAvailable = []
      skusNotAvailableIn.map(avail => skusNotAvailable.push(avail.sku))
      if (skusNotAvailable.includes(cart.cartItems[i].sku)) {
        cart.cartItems.splice(i, 1)
      }
    }
    if (cart.cartItems && cart.cartItems.length === 0) {
      cart = {
        cartItems: [],
      }
    }
    Object.keys(sessionStorage).forEach(key => {
      if (key !== 'shippingInfo' && key !== 'cart') sessionStorage.removeItem(key)
    })
    store.dispatch(setCart(cart))
    setCartState({
      skusNotAvailable: [],
    })
  }
}

export const loadCartFromOrder = async orderId => {
  fetchOrder({
    orderId,
  }).then(order => {
    const cartItemPromises = []
    if (order && order.lineItems && order.lineItems.length > 0) {
      order.lineItems.forEach(item => {
        cartItemPromises.push(
          fetchProductOrGC(item.sku).then(product => ({
            sku: product.sku,
            quantity: item.quantity,
            product: cartProduct(product),
            price: productPrice(product),
            activeAddons: null,
          })),
        )
      })
      Promise.all(cartItemPromises).then(cartItems => {
        store.dispatch(
          setCart({
            cartItems,
          }),
        )
      })
    }
  })
}

export const getPromoTargetSkus = () => {
  const order = getOrder()
  if (order && order.promotions && order.promotions.availableTargetSkus) {
    return order.promotions.availableTargetSkus
  }
  return null
}

export const updateAddOnWarranty = async (sku, warrantyEnabled, cartIndex) => {
  const order = getOrder()
  const lineItems = getLineItems()
  const newLineItems = lineItems.map(addon => {
    if (addon.sku === sku) {
      addon.warrantyEnabled = warrantyEnabled
    }
    return addon
  })

  const newOrder = {
    ...order,
    ...{
      lineItems: newLineItems,
    },
  }

  await setAddOnWarrantyEnabled(sku, warrantyEnabled, cartIndex)
  store.dispatch(setOrder(newOrder))
}
